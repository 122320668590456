import React, { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import patientDetailsIcon from "../../../images/patient_details.svg";
import treatmentDetailsIcon from "../../../images/treatment_details.svg";
import FileUploaderService from "../../../services/fileUploader.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QuestionMark from "@mui/icons-material/Help";
import PatientService from "../../../services/patient.service";
import AdminService from "../../../services/admin.service";
import ToothChartService from "../../../services/toothChart.service";
import ProductDetailModal from "./productDetailModal/productDetailModal";
import AdditionalFilesModal from "./additionalFilesModal/additionalFilesModal";
import './inputTreatmentDetail.css';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CrownAndBridge from "./treatmentTypes/crownAndBridge";
import DiagnosticWaxUpForm from "./treatmentTypes/diagnosticWaxUp";

import {
  Alert,
  Checkbox,
  Container,
  IconButton,
  Stack,
  Typography,
  Popover,
} from "@mui/material";

import PatientDetails from "../../common/patientDetails";
import TreatmentForms from "./treatmentTypes/treatmentForms";
import setFilePhoto from "../../../images/set_file_photo.svg";
import AdditionalFiles from "../../../images/additionalFiles.svg";

import CaseService from "../../../services/case.service";
import StyledButton from "../../common/button/largeButton";
import { textAlign } from "@mui/system";
import moment from "moment";
import FilesComponent from "../../requirementComponents/filesComponent";
import RequirementBoxCount from "../../common/RequirementBoxCount";
import IframeDialog from "../../common/iframeDialog";
import { fileSetUpload } from "../../../helpers/fileSetUpload";
import LargeButton from "../../common/button/largeButton";
import SubHeader from "../../subHeader/subheader";
import BackupIcon from "@mui/icons-material/Backup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import useSegment from "../../../hooks/useSegment";

import {
  brandBlack,
  brandBlue,
  brandGray,
  brandLight,
} from "../../../constants";

import { openSnackbar, updatePatientId, updateUseCurrentScan } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

function getPacificDateTime() {
  const time = new Date().toLocaleTimeString("en-US", {
    timeZone: "America/Los_Angeles",
    hourCycle: "h23",
  });
  const dayOfWeek = new Date().toLocaleDateString("en-US", {
    timeZone: "America/Los_Angeles",
    weekday: "long",
  });

  let pacificDayandTime = {
    day: dayOfWeek.toString(),
    time: time.toString(),
    hour: time.split(":")[0],
  };
  return pacificDayandTime;
}
let dayandtimeChecker = getPacificDateTime();

function mapStateToProps(state) {
  return { userInfo: state.userInfo };
}

function InputTreatmentDetailsBeta({
  selectedTreatment,
  patientDetails,
  handleNextStep,
  userInfo,
  setSelectedTreatment,
  additionalOrderDetails,
  isCancelled
}) {

  const dispatch = useDispatch();
  const uploadedIds = React.useRef([]);
  const fileCountToUpload = React.useRef(0);
  const setNamesForUpload = [
    "Clinical Photography",
    "IOS Scan (STL Files) (Upper, Lower and Bite)",
  ];

  const segment = useSegment();

  const [showGraphyWorkflow, setShowGraphyWorkflow] = React.useState(true);
  const [treatmentRequirementCount, setTreatmentRequirementCount] =
    React.useState(0);

  const preferenceGuideRef = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [treatmentsSelected, setTreatmentsSelected] =
    React.useState(selectedTreatment);
  const [allPatients, setAllPatients] = React.useState([]);
  const [patientsList, setPatientsList] = React.useState([]);
  const [practiceList, setPracticeList] = React.useState([]);
  const [dentistsList, setDentistList] = React.useState([]);
  const [treatmentDetailToDisplayInModal, setTreatmentDetailToDisplayInModal] =
    React.useState(selectedTreatment);

  const [patientId, setPatientId] = React.useState(patientDetails?.Id);
  const [lastName, setLastName] = React.useState(patientDetails?.LastName);
  const [firstName, setFirstName] = React.useState(patientDetails?.FirstName);
  const [birthday, setBirthday] = React.useState(
    patientDetails?.DateofBirth !== null || patientDetails?.DateofBirth !== ""
      ? patientDetails?.DateOfBirth
      : null
  );
  const [email, setEmail] = React.useState(patientDetails?.Email);
  const [practiceId, setPracticeId] = React.useState(
    patientDetails?.PracticeId
  );
  const [personnelId, setPersonnelId] = React.useState(
    patientDetails?.PersonnelId
  );
  const [requiredFilesOpen, setRequiredFilesOpen] = React.useState(false);
  const [returnDate, setReturnDate] = React.useState(new Date());

  const [isSameDayDentistry, setIsSameDayDentistry] = React.useState(false);
  const [isRush, setIsRush] = React.useState(patientDetails?.IsRush || false);
  const [isPrepOrFinalRestoration, setIsPrepOrFinalRestoration] =
    React.useState(true);

  const [productDetailModalOpen, setProductDetailModalOpen] =
    React.useState(false);
  const [additionalFilesModalOpen, setAdditionalFilesModalOpen] =
    React.useState(false);
  const [productDetail, setProductDetail] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [mappedFiles, setMappedFiles] = React.useState([]);
  const [filteredMappedFiles, setFilteredMappedFiles] = React.useState([]);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const [notion, setNotion] = React.useState("");
  const [uploading, setUploading] = React.useState(false);

  const [isShowServiceProviders, setIsShowServiceProviders] =
    React.useState(false);

  const [isUsingGraphyWorkflow, setIsUsingGraphyWorkflow] =
    React.useState(false);

  const [displayGraphyInfo, setDisplayGraphyInfo] = React.useState(false);

  const [proceeding, setProceeding] = React.useState(false);
  const [isComponentVisible, setIsComponentVisible] = React.useState([
    {
      description: "Tooth Chart",
      visible: true,
    },
    {
      description: "Does your patient have any special considerations?",
      visible: true,
    },
    {
      description: "Do you want to add a Stackable Guide?",
      visible: true,
    },
    {
      description: "Arch Design",
      visible: true,
    },
    {
      description: "Does this include a restorative design?",
      visible: true,
    },
    {
      description: "Alignment On Semi-Adjustable Articulator",
      visible: true,
    },
    {
      description: "Articulator",
      visible: true,
    },
    {
      description: "Tooth Arch",
      visible: true,
    },
    {
      description: "Type of Framework",
      visible: true,
    },
    {
      description: "Manufacturing Material",
      visible: true,
    },
    {
      description: "Design Teeth For Partial Rests",
      visible: true,
    },
    {
      description: "Mesial Occlusal Tooth #",
      visible: true,
    },
    {
      description: "Distal Occlusal Tooth #",
      visible: true,
    },
    {
      description: "Cingulum Tooth #",
      visible: true,
    },
    {
      description: "Ball Tooth #",
      visible: true,
    },
    {
      description: "Circumferential Tooth #",
      visible: true,
    },
    {
      description: "I-Bar Tooth #",
      visible: true,
    },
    {
      description: "T-Bar Tooth #",
      visible: true,
    },
    {
      description: "RPA Tooth #",
      visible: true,
    },
    {
      description: "RPI Tooth #",
      visible: true,
    },
    {
      description: "Ring Tooth #",
      visible: true,
    },
    {
      description: "Wrought Wire Tooth #",
      visible: true,
    },
    {
      description: "Flexible Combo Tooth #",
      visible: true,
    },
    {
      description: "Provide Drawing Instructions (Optional)",
      visible: true,
    },
    {
      description: "Library To Be Used",
      visible: true,
    },
    {
      description: "Teeth Segmentation",
      visible: true,
    },
    {
      description: "Denture Output",
      visible: true,
    },
    {
      description:
        "Denture Base Outline Marked On Cast (Ideally Provided By Customer)",
      visible: true,
    },
    {
      description: "Support Bar",
      visible: true,
    },
    {
      description: "Where To Place Clasps",
      visible: true,
    },
    {
      description: "Tooth Shade",
      visible: true,
    },
    {
      description: "Tooth Shape",
      visible: true,
    },
    {
      description: "Max Incisal Edge Position",
      visible: true,
    },
    {
      description: "Platform Height",
      visible: true,
    },
    {
      description: "Gingival Levels",
      visible: true,
    },
    {
      description: "Collar Height Buccal",
      visible: true,
    },
    {
      description: "Collar Height Lingual",
      visible: true,
    },
    {
      description: "Scanbody",
      visible: true,
    },
    {
      description: "Kind of Implant",
      visible: true,
    },
    {
      description: "Emergence Profile",
      visible: true,
    },
    {
      description: "Tissue Pressure",
      visible: true,
    },
    {
      description: "Deprogrammer Options",
      visible: true,
    },
    {
      description: "Nightguard Options",
      visible: true,
    },
    {
      description: "Mandibular Anterior Jig Options",
      visible: true,
    },
    {
      description: "Orthopedic Appliance Options",
      visible: true,
    },
    {
      description: "Measurement",
      visible: true,
    },
    {
      description: "Hollow or Solid Models?",
      visible: true,
    },
    {
      description: "Do you need your models based for vertical printing?",
      visible: true,
    },
    {
      description: "Are you using the GRAPHY workflow?",
      visible: true,
    },
    {
      description: "Are you fabricating In-house?",
      visible: true,
    },
    {
      description: "Interproximal Reduction (IPR)",
      visible: true,
    },
    {
      description: "Anterior Alignment Preference",
      visible: true,
    },
    {
      description: "Is Patient Open To A Lower Incisor Extraction?",
      visible: true,
    },
    {
      description: "Is Patient a Bruxer/Grinder With Visible Tooth Wear",
      visible: true,
    },
    {
      description: "How Would You Like To Deal With Spaces?",
      visible: true,
    },
    {
      description: "Does Patient Have Anterior Crossbite Or Open / Deep Bite?",
      visible: true,
    },
    {
      description:
        "Attachments May Be Required To Increase Predictability Of Movements. If You Need To Delay Attachments, Please Indicate Aligner Stage:",
      visible: true,
    },
    {
      description:
        "Patient Complaint And Other Preference/Notations For This Case",
      visible: true,
    },
    {
      description: "Please indicate any restorations",
      visible: true,
    },
    {
      description: "Attachments",
      visible: true,
    },
    {
      description: "Anterior Alignment Preference",
      visible: true,
    },
    {
      description: "The STL files I want to have are",
      visible: true,
    },
    {
      description: "What is the patient's main complaint?",
      visible: true,
    },
    {
      description: "Default Desired Output Type/s",
      visible: true,
    },
    {
      description: "Type of Complete Dentures",
      visible: true,
    },
    {
      description: "Arches",
      visible: true,
    },
    {
      description: "Is This An Immediate Denture",
      visible: true,
    },
    {
      description: "Desired Base Thickness",
      visible: true,
    },
    {
      description: "Default Teeth Segmentation",
      visible: true,
    },
    {
      description: "Desired Overjet & Overbite",
      visible: true,
    },
    {
      description: "Gingiva Esthetics / Festooning",
      visible: true,
    },
    {
      description: "Support Bars For Printing",
      visible: true,
    },
    {
      description: "Digital Tooth Library",
      visible: true,
    },
    {
      description: "RX Notes",
      visible: true,
    },
    {
      description: "Maxillary/Mandibular/Bite Scan Sent",
      visible: false,
    },
    {
      description: "Workflow",
      visible: false,
    },
    {
      description: "Occlusal Scheme",
      visible: false,
    },
    {
      description: "Reference",
      visible: false,
    },
    {
      description: "Ridge Relationship",
      visible: false,
    },
    {
      description: "Vertical Dimension of Occlusion",
      visible: false,
    },
    {
      description: "Tooth Color",
      visible: false,
    },
    {
      description: "Gingival Shades",
      visible: false,
    },
    {
      description: "Tooth Mould/Shape Anterior",
      visible: false,
    },
    {
      description: "Tooth Mould/Shape Posterior",
      visible: false,
    },
    {
      description: "Papillameter Rest",
      visible: false,
    },
    {
      description: "Papillameter High Lip Line",
      visible: false,
    },
    {
      description: "Are you fabricating In-house ?", //for truedent
      visible: false,
    },
    {
      description: "Radial Spacer",
      visible: false,
    },
    {
      description: "Occlusal Spacer",
      visible: false,
    },
    {
      description: "Proximal Contact Strength",
      visible: false,
    },
    {
      description: "Occlusal Contact Strength",
      visible: false,
    },
    {
      description: "Dynamic Contact Strength",
      visible: false,
    },
    {
      description: "Minimal Radial Thickness",
      visible: false,
    },
    {
      description: "Minimal Occlusal Thickness",
      visible: false,
    },
    {
      description: "Margin Thickness",
      visible: false,
    },
    {
      description: "Width of Ramp",
      visible: false,
    },
    {
      description: "Angle of Ramp",
      visible: false,
    },
  ]);

  const patientIdForAdditionalOrder = useSelector((state) => state.patientId);
  const useCurrentScan = useSelector((state)=>state.useCurrentScan);

  function graphyQuestions(usingGraphy, fabrication) {
    if (treatmentsSelected[0].description === "Aligners") {
      if (usingGraphy === true) {
        let newArr = isComponentVisible.map((item, i) => {
          if (
            (item.description === item.description) ===
            "Are you using the GRAPHY workflow?"
          ) {
            return {
              ...item,
              visible: false,
            };
          } else if (
            item.description === "What is the patient's main complaint?" ||
            item.description === "Please indicate any restorations" ||
            item.description ===
              "Does Patient Have Anterior Crossbite Or Open / Deep Bite?" ||
            item.description === "How Would You Like To Deal With Spaces?" ||
            item.description ===
              "Is Patient a Bruxer/Grinder With Visible Tooth Wear" ||
            item.description === "Anterior Alignment Preference" ||
            item.description === "Interproximal Reduction (IPR)" ||
            item.description === "Are you fabricating In-house?" ||
            item.description === "The STL files I want to have are"
          ) {
            return {
              ...item,
              visible: true,
            };
          } else {
            return {
              ...item,
              visible: false,
            };
          }
        });
        newArr.forEach(function (element) {
          console.log(element);
        });
        setIsComponentVisible(newArr);
      }
      if (usingGraphy === false) {
        let newArr = isComponentVisible.map((item, i) => {
          if (fabrication === "Fabricate") {
            if (
              item.description === "Hollow or Solid Models?" ||
              item.description ===
                "Do you need your models based for vertical printing?" ||
              item.description === "Are you fabricating In-house?" ||
              item.description === "What is the patient's main complaint?" ||
              item.description === "The STL files I want to have are" ||
              item.description === "Are you using the GRAPHY workflow?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
                visible: true,
              };
            }
          } else {
            if (
              item.description === "What is the patient's main complaint?" ||
              item.description === "The STL files I want to have are" ||
              item.description === "Are you using the GRAPHY workflow?" ||
              item.description === "Are you fabricating In-house?"
            ) {
              return {
                ...item,
                visible: false,
              };
            } else {
              return {
                ...item,
                visible: true,
              };
            }
          }
        });
        console.log(newArr);
        newArr.forEach(function (element) {
          console.log(element);
        });
        setIsComponentVisible(newArr);
      }
    }
  }
  React.useEffect(() => {
    console.log("389 - Getting files")
    if (!patientDetails.Id || patientDetails.Id === "") return;

    let mounted = true;

    if(useCurrentScan){
      if (selectedTreatment.requiredFiles.every((f) => f.files.length === 0)) {
        PatientService.getAllMappedFiles(patientDetails.Id)
          .then((response) => response.json())
          .then((json) => {
            if (mounted) {
              setMappedFiles(json);
              setFilteredMappedFiles([]);
            }
          });
      }
    }

    return () => {
      mounted = false;
    };
  }, [patientDetails.Id, selectedTreatment]);

  React.useEffect(() => {
    let isMounted = true;

    const resetRequiredFiles = () => {
      const newSelectedTreatment = [...selectedTreatment];
      newSelectedTreatment.requiredFiles = selectedTreatment.requiredFiles;
      newSelectedTreatment.requiredFiles.forEach((section) => {
        section.files = [];
      });
      updateSelectedTreatment(newSelectedTreatment);
    };

    if (mappedFiles.length === 0) {
      resetRequiredFiles();
      return;
    }

    const files = treatmentsSelected.requiredFiles.map((section) => {
      return [...section.files];
    });
    let count = 0;
    files.forEach((f) => {
      count += f.length;
    });

    // has mapped files and has files in required files
    if (mappedFiles.length > 0 && count > 0) {
      resetRequiredFiles();
    }

    // also ignore other and design file file type
    const fileTypesAdded = ["Other", "Design File"];

    let copyMappedFiles = mappedFiles;
    if (mappedFiles) {
      if (
        selectedTreatment.requiredFiles.some(
          (j) => j.name === "IOS Scan (STL Files) (Upper, Lower and Bite)"
        )
      ) {
        let ios = mappedFiles.find((x) => x.fileType === "Intraoral Scan");
        copyMappedFiles = copyMappedFiles.filter(
          (x) => x.fileType !== "Intraoral Scan"
        );
        if (ios) {
          fileTypesAdded.push("Intraoral Scan");
          ios.files.forEach((y) => {
            const set = y.fileName;
            PatientService.getPatientFileExtension(
              y.patientFileId,
              y.patientId,
              y.fileType
            )
              .then((response1) => response1.json())
              .then((json1) => {
                json1.forEach((pFile) => {
                  let linkToPatient = {
                    PatientId: patientId,
                    FileId: pFile.fileId,
                    Filename: pFile.fileName,
                  };

                  let newFile = {
                    idx: 0,
                    fileName: pFile.fileName,
                    fileType: "Intraoral Scan",
                    fileId: pFile.fileId,
                    patientFileId: y.id,
                    patientFileExtensionId: pFile.id,
                    uploadedDate: pFile.uploadedDate,
                    set,
                  };
                  var treatsToUpdate = [...treatmentsSelected];
                  treatsToUpdate[0].files =
                    treatsToUpdate[0].files.concat(newFile);
                  treatsToUpdate.requiredFiles =
                    treatmentsSelected.requiredFiles;
                  var a = treatsToUpdate.requiredFiles.find(
                    (x) =>
                      x.name == "IOS Scan (STL Files) (Upper, Lower and Bite)"
                  );
                  a.files.push(newFile);
                  a.files.sort(
                    (a, b) =>
                      Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
                  );
                  setTreatmentsSelected(treatsToUpdate);
                });
              });
          });
        }
      }

      if (
        selectedTreatment.requiredFiles.some(
          (j) => j.name === "Clinical Photography"
        )
      ) {
        let cp = mappedFiles.find((x) => x.fileType === "Clinical Photography");
        copyMappedFiles = copyMappedFiles.filter(
          (x) => x.fileType !== "Clinical Photography"
        );
        //Clinical Photography
        if (cp) {
          fileTypesAdded.push("Clinical Photography");
          cp.files.forEach((y) => {
            const set = y.fileName;
            PatientService.getPatientFileExtension(
              y.patientFileId,
              y.patientId,
              y.fileType
            )
              .then((response1) => response1.json())
              .then((json1) => {
                json1.forEach((pFile) => {
                  let linkToPatient = {
                    PatientId: patientId,
                    FileId: pFile.fileId,
                    Filename: pFile.fileName,
                  };

                  let newFile = {
                    idx: 1,
                    fileName: pFile.fileName,
                    fileId: pFile.fileId,
                    patientFileId: y.id,
                    patientFileExtensionId: pFile.id,
                    uploadedDate: pFile.uploadedDate,
                    fileType: "Clinical Photography",
                    set,
                  };
                  var treatsToUpdate = [...treatmentsSelected];

                  treatsToUpdate[0].files =
                    treatsToUpdate[0].files.concat(newFile);
                  treatsToUpdate.requiredFiles =
                    treatmentsSelected.requiredFiles;
                  var a = treatsToUpdate.requiredFiles.find(
                    (x) => x.name == "Clinical Photography"
                  );
                  a.files.push(newFile);
                  a.files.sort(
                    (a, b) =>
                      Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
                  );
                  setTreatmentsSelected(treatsToUpdate);

                  // if (isMounted) {
                  //   selectedTreatment.files =
                  //     selectedTreatment.files.concat(newFile);

                  //   setTreatmentDetails(treatmentDetails);
                  //   if (handleTreatmentUpdate) {
                  //     handleTreatmentUpdate(treatmentDetails);
                  //   }

                  //   let requiredFilesCopy = Object.assign(
                  //     [],
                  //     requiredFiles
                  //   );
                  //   var a = requiredFilesCopy.find(
                  //     (x) => x.name == "Clinical Photography"
                  //   );
                  //   a.files.push(newFile);
                  //   a.files.sort(
                  //     (a, b) =>
                  //       Date.parse(b.uploadedDate) -
                  //       Date.parse(a.uploadedDate)
                  //   );

                  //   setRequiredFiles(requiredFilesCopy);
                  // }
                });
              });
          });
        }
      }

      if (selectedTreatment.requiredFiles.some((j) => j.name === "Cbct Scan")) {
        let cb = mappedFiles.find((x) => x.fileType === "Cbct Scan");
        copyMappedFiles = copyMappedFiles.filter(
          (x) => x.fileType !== "Cbct Scan"
        );
        //CBCT
        if (cb) {
          fileTypesAdded.push("Cbct Scan");
          cb.files.forEach((y) => {
            let newFile = {
              idx: 2,
              fileName: y.fileName,
              fileId: y.patientFileId,
              patientFileId: y.id,
              uploadedDate: y.uploadedDate,
              fileType: "CBCT Scan",
            };
            var treatsToUpdate = [...treatmentsSelected];
            treatsToUpdate.requiredFiles = treatmentsSelected.requiredFiles;
            treatsToUpdate[0].files = treatsToUpdate[0].files.concat(newFile);
            var a = treatsToUpdate.requiredFiles.find(
              (x) => x.name == "Cbct Scan"
            );
            a.files.push(newFile);
            a.files.sort(
              (a, b) => Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
            );

            setTreatmentsSelected(treatsToUpdate);
            // if (isMounted) {
            //   treatmentDetails.files = treatmentDetails.files.concat(newFile);

            //   setTreatmentDetails(treatmentDetails);
            //   if (handleTreatmentUpdate) {
            //     handleTreatmentUpdate(treatmentDetails);
            //   }

            //   let requiredFilesCopy = Object.assign([], requiredFiles);
            //   var a = requiredFilesCopy.find((x) => x.name == "Cbct Scan");
            //   a.files.push(newFile);
            //   a.files.sort(
            //     (a, b) =>
            //       Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
            //   );

            //   setRequiredFiles(requiredFilesCopy);
            // }
          });
        }
      }
    }
    // FOR FILES UPLOADED IN SAME DAY, ADDITIONAL FILES
    // remove added files from mappedFiles
    const fileTypesForAdditional = mappedFiles.filter(
      (file) => !fileTypesAdded.includes(file.fileType)
    );

    const newTreatments = [...treatmentsSelected];
    newTreatments.requiredFiles = [...treatmentsSelected.requiredFiles];

    const additionalFiles = newTreatments.requiredFiles.find(
      (fileSection) => fileSection.name === "Additional Files"
    );

    fileTypesForAdditional.forEach((fileType) => {
      fileType.files.forEach(async (file) => {
        const dateOfFile = new Date(file.uploadedDate).toDateString();
        const today = new Date().toDateString();
        // early return, only add files uploaded the same day as today
        if (dateOfFile !== today) return;

        const fileTypesWithExtensions = [
          "Intraoral Scan",
          "Clinical Photography",
        ];
        // fileType name
        if (fileTypesWithExtensions.includes(fileType.fileType)) {
          // get files in set
          const response = await PatientService.getPatientFileExtension(
            file.patientFileId,
            file.patientId,
            file.fileType
          );
          const filesInSet = await response.json();

          filesInSet.forEach(async (fileInSet) => {
            let fileDetails = {
              idx: 3,
              fileName: fileInSet.fileName,
              fileId: fileInSet.fileId,
              patientFileId: file.id,
              patientFileExtensionId: fileInSet.id,
              uploadedDate: fileInSet.uploadedDate,
              fileType: fileType.fileType,
              set: file.fileName, // set name
            };

            additionalFiles.files.push(fileDetails);
          });
        } else {
          const fileDetails = {
            idx: 3,
            fileName: file.fileName,
            fileId: file.patientFileId,
            patientFileId: file.id,
            uploadedDate: file.uploadedDate,
            fileType:
              file.fileTypeName === "Cbct Scan"
                ? "CBCT Scan"
                : file.fileTypeName, // change Cbct Scan to CBCT Scan
          };
          additionalFiles.files.push(fileDetails);
        }
      });
    });

    // FOR ADDITIONAL CASE DETAILS, AUTO ATTACH FILES IN additionalOrderDetails.filePathsToInclude

    const { filePathsToInclude } = additionalOrderDetails || {};
    if (!filePathsToInclude || filePathsToInclude.length === 0) {
      setTreatmentsSelected(newTreatments);
      setFilteredMappedFiles(copyMappedFiles);
      return;
    }
    const allFiles = mappedFiles.map((fileType) => fileType.files).flat();
    const filesToInclude = allFiles.filter((file) =>
      filePathsToInclude.includes(file.path)
    );
    filesToInclude.forEach((file) => {
      const fileDetails = {
        idx: 3,
        fileName: file.fileName,
        fileId: file.patientFileId,
        patientFileId: file.id,
        uploadedDate: file.uploadedDate,
        fileType:
          file.fileTypeName === "Cbct Scan" ? "CBCT Scan" : file.fileTypeName, // change Cbct Scan to CBCT Scan
      };
      additionalFiles.files.push(fileDetails);
    });

    setTreatmentsSelected(newTreatments);
    setFilteredMappedFiles(copyMappedFiles);
    return () => {
      isMounted = false;
    };
  }, [mappedFiles]);

  React.useEffect(() => {
    setNotion(ToothChartService.getDentalNotationSystem());
    AdminService.getPractices(userInfo.Id)
      .then((response) => response.json())
      .then((json) => {
        setPracticeList(json.practices);
        if (userInfo.Roles.includes("Dentist")) {
          let defaultPractice = json.practices.find(
            (x) => x.isPrimary === true
          );
          if (
            defaultPractice !== null &&
            defaultPractice !== undefined &&
            defaultPractice !== "undefined" &&
            (!Boolean(practiceId) || practiceId === "")
          ) {
            setPracticeId(defaultPractice.id);
          }
        } else {
          // create practice dentists dictionary/object
          const practiceDentists = {};
          userInfo.PracticeWithPersonnel.forEach((pp) => {
            // if practice already in object append to array, else initialize it
            const value = practiceDentists[pp.practiceId];
            practiceDentists[pp.practiceId] = value
              ? [...value, pp.personnelId]
              : [pp.personnelId];
          });

          const dentistsPromises = json.practices.map(async (practice) => {
            const response = await AdminService.getDentistsByPracticeid(
              practice.id
            );
            const data = await response.json();
            return data;
          });

          Promise.all(dentistsPromises).then((values) => {
            const dentists = {};
            json.practices.forEach(
              (practice, i) =>
                (dentists[practice.id] = values[i].filter((dentist) =>
                  practiceDentists[practice.id].includes(dentist.id)
                ))
            );
            setDentistList(dentists);
          });
        }

        var pId = userInfo.PracticeWithPersonnel.map((x) => x.personnelId);

        PatientService.getPatientList(pId)
          .then((response) => response.json())
          .then((json) => {
            setAllPatients(json);
          });
      });

    AdminService.getProductProfiles()
      .then((response) => response.json())
      .then((json) => {
        setProductDetail(json);
      });
    // CaseService.getRequiredFiles()
    if (selectedTreatment[0].files.length > 0) {
      let requiredFilesCopy = [
        {
          name: "IOS Scan (STL Files) (Upper, Lower and Bite)",
          image: setFilePhoto,
          files: [],
        },
        {
          name: "Clinical Photography",
          image: setFilePhoto,
          files: [],
        },
        {
          name: "Additional Files",
          image: AdditionalFiles,
          files: [],
        },
      ];

      selectedTreatment[0].files.forEach((x) => {
        requiredFilesCopy[x.idx].files.push(x);
      });
    }
  }, []);



  

  React.useEffect(() => {
    if (practiceId && allPatients.length > 0) {
      setPatientsList(() => {
        const patientsPractice = allPatients.filter(
          (patient) => patient.practiceId === practiceId
        );

        if (!userInfo.Roles.includes("Dentist")) {
          return patientsPractice
            .filter((patient) => patient.personnelId === personnelId)
            .sort((a, b) => a.lastName.localeCompare(b.lastName));
        } else {
          return patientsPractice.sort((a, b) =>
            a.lastName.localeCompare(b.lastName)
          );
        }
      });
    }
  }, [allPatients, practiceId, personnelId, userInfo.Roles]);

  useEffect(()=>{
    if(patientIdForAdditionalOrder && patientsList){
      const patientForAdditionalOrder = patientsList.filter(p => p.id == patientIdForAdditionalOrder);
      if(patientForAdditionalOrder.length){
        onSelectLastName(patientForAdditionalOrder[0]);
        dispatch(updatePatientId({patientId: null}));
      }
    }
  },[patientIdForAdditionalOrder,patientsList]);

  React.useEffect(() => {
    // selectedTreatment.forEach((treatm) => {
    //   if (treatm.description === "Evident Smile Package") {
    //     treatm.productSubDescription =
    //       "https://evidentdigital.com/smile-package";
    //   } else if (treatm.description === "Diagnostic Wax-Up") {
    //     treatm.productSubDescription =
    //       "https://evidentdigital.com/diagnostic-wax-up";
    //   } else {
    //     treatm.productSubDescription = "";
    //   }
    // });

    setTreatmentsSelected(selectedTreatment);
    if (selectedTreatment[0].treatmentTypeServiceProviders.length > 1) {
      setIsShowServiceProviders(true);
    } else if (
      selectedTreatment[0].treatmentTypeServiceProviders.length === 1
    ) {
      let treatmentsSelectedCopy = Object.assign([], selectedTreatment);

      treatmentsSelectedCopy[0].serviceProviderSelected =
        selectedTreatment[0].treatmentTypeServiceProviders[0].serviceProvider;

      setTreatmentsSelected(treatmentsSelectedCopy);

      setIsShowServiceProviders(false);
    }
  }, [selectedTreatment]);

  const updateSelectedTreatment = (copy) => {
    // tooth numbers not updated in selected treatment
    treatmentsSelected.forEach((t, i) => {
      copy[i].toothNumbers = [...t.toothNumbers];
    });

    if (!copy.requiredFiles) {
      // copy required files
      copy.requiredFiles = [...selectedTreatment.requiredFiles];
    }

    setSelectedTreatment(copy);
  };

  const fileTypes = {
    "IOS Scan (STL Files) (Upper, Lower and Bite)": "Intraoral Scan",
    "Clinical Photography": "Clinical Photography",
    "Cbct Scan": "CBCT Scan",
    "Additional Files": "Other",
  };

  const onFileChange = (files, idx, setName) => {
    let isInvalid = false;
    if (setName === "IOS Scan (STL Files) (Upper, Lower and Bite)") {
      files.forEach((x) => {
        const stlTypes = [
          "stl",
          "ply",
          "obj",
          "zip",
          "ZIP",
          "rar",
          "RAR",
          "7z",
          "7Z",
          "dxd",
        ];
        if (!stlTypes.includes(x.name.split(".").pop().toLowerCase())) {
          isInvalid = true;
        }
      });
    }

    if (isInvalid) {
      dispatch(
        openSnackbar({
          title: "File upload",
          type: "warning",
          body: "Please select a STL, PLY, OBJ, ZIP, RAR, DXD, or 7z file",
        })
      );
      return;
    } else if (files.length > 0) {
      handleUploadChange(true);
      if (patientId === "" || !Boolean(patientId)) {
        // copied from file upload callback
        files.forEach((file) => {
          let newFile = {
            idx: idx,
            fileName: file.name,
            fileId: FileUploaderService.generateGuid(),
            patientFileId: "00000000-0000-0000-0000-000000000000",
            setName: setName,
            file: file,
            fileType: fileTypes[setName],
          };

          // also update similar code in fileUploadCallback
          treatmentsSelected[0].files =
            treatmentsSelected[0].files.concat(newFile);

          setTreatmentsSelected(treatmentsSelected);

          const copy = [...treatmentsSelected];
          copy.requiredFiles = [...selectedTreatment.requiredFiles];
          copy.requiredFiles[idx].files = [
            ...copy.requiredFiles[idx].files,
            newFile,
          ];
          updateSelectedTreatment(copy);
          // copy[0].files = copy[0].files.concat(newFile);
          // copy.requiredFiles[idx].files.push(newFile);

          // TODO: replcace with setSelectedTreatment to update file list
          // forceUpdate();
        });

        return handleUploadChange(false);
      }

      selectedTreatment.requiredFiles[idx].numberOfFilesUploading =
        (selectedTreatment.requiredFiles[idx].numberOfFilesUploading || 0) +
        files.length;

      for (var i = 0; i < files.length; i++) {
        let x = files[i];

        let generateFileId = FileUploaderService.generateGuid();

        let blobName =
          "patient/" +
          "00000000-0000-0000-0000-000000000000" +
          "/" +
          generateFileId;

        if (patientId !== "") {
          blobName = "patient/" + patientId + "/" + generateFileId;
        }

        let fileObj = {
          idx: idx,
          treatmentId: selectedTreatment[0].id,
          fileId: generateFileId,
          fileName: x.name,
          fileType: fileTypes[setName],
          patientFileId: "",
          patientExtensionId: "",
          setName,
        };

        if (setNamesForUpload.includes(fileObj.setName)) {
          fileCountToUpload.current = files.length;
        }

        const onError = (err) => {
          dispatch(
            openSnackbar({
              title: "File upload",
              type: "error",
              body: x.name + " cannot be uploaded",
            })
          );
          handleUploadChange(false);
          fileCountToUpload.current = 0;
          selectedTreatment.requiredFiles[idx].numberOfFilesUploading = 0;
        };

        FileUploaderService.uploadFile(
          x,
          blobName,
          fileUploadCallback,
          fileObj,
          onError
        );
      }
    }

    // let documentId = "file-upload-" + treatment.id;

    // document.getElementById(documentId).value = "";
  };

  const fileUploadCallback = (progress, fileObj) => {
    if (progress === 100) {
      if (patientId !== "") {
        let linkToPatient = {
          PatientId: patientId,
          FileId: fileObj.fileId,
          Filename: fileObj.fileName,
        };

        PatientService.uploadPatientFile(linkToPatient, fileObj.setName)
          .then((response) => response.json())
          .then((json) => {
            const isFileExtension = setNamesForUpload.includes(fileObj.setName);
            let newFile = {
              idx: fileObj.idx,
              fileName: fileObj.fileName,
              fileId: fileObj.fileId,
              fileType: fileTypes[fileObj.setName],
              patientFileId: isFileExtension ? null : json.id,
              patientFileExtensionId: isFileExtension
                ? json.id
                : "00000000-0000-0000-0000-000000000000",
            };

            // If this code is updated, also update onFileChange if patientId = ""
            treatmentsSelected[0].files =
              treatmentsSelected[0].files.concat(newFile);

            selectedTreatment.requiredFiles[fileObj.idx].files.push(newFile);
            selectedTreatment.requiredFiles[
              fileObj.idx
            ].numberOfFilesUploading =
              selectedTreatment.requiredFiles[fileObj.idx]
                .numberOfFilesUploading - 1;

            if (isFileExtension) {
              uploadedIds.current.push(json.id);

              const uploadFinished =
                uploadedIds.current.length === fileCountToUpload.current;

              if (uploadFinished) {
                handleUploadFileSet(fileObj.setName);
              }
            }

            if (
              selectedTreatment.requiredFiles[fileObj.idx]
                .numberOfFilesUploading === 0 &&
              !isFileExtension
            ) {
              handleUploadChange(false);
            }
          });
      } else {
        // Won't reach anymore due to early reaturn in onFileChange
        // No patientId
        let newFile = {
          idx: fileObj.idx,
          fileName: fileObj.fileName,
          fileType: fileTypes[fileObj.setName],
          fileId: fileObj.fileId,
          patientFileId: "00000000-0000-0000-0000-000000000000",
          setName: fileObj.setName,
        };

        selectedTreatment[0].files = selectedTreatment[0].files.concat(newFile);

        selectedTreatment.requiredFiles[fileObj.idx].files.push(newFile);
        selectedTreatment.requiredFiles[fileObj.idx].numberOfFilesUploading =
          selectedTreatment.requiredFiles[fileObj.idx].numberOfFilesUploading -
          1;

        if (
          selectedTreatment.requiredFiles[fileObj.idx]
            .numberOfFilesUploading === 0
        ) {
          handleUploadChange(false);
        }
      }
      setTreatmentsSelected(treatmentsSelected);
    }
  };

  const removeFile = (requireIdx, fileIdx) => {
    let fileCopy = Object.assign(
      {},
      selectedTreatment.requiredFiles[requireIdx].files[fileIdx]
    );
    const copySelectedTreatment = [...treatmentsSelected];

    copySelectedTreatment.requiredFiles = [...selectedTreatment.requiredFiles];
    copySelectedTreatment.requiredFiles[requireIdx].files.splice(fileIdx, 1);
    copySelectedTreatment[0].files = selectedTreatment[0].files.filter(
      (x) => x.fileId !== fileCopy.fileId
    );
    updateSelectedTreatment(copySelectedTreatment);

    treatmentsSelected[0].files = treatmentsSelected[0].files.filter(
      (x) => x.fileId !== fileCopy.fileId
    );

    handleUploadChange(false);
    // setTreatmentDetails(treatmentDetailsCopy);

    // if (handleTreatmentUpdate) {
    //   handleTreatmentUpdate(treatmentDetailsCopy);
    // }
  };

  const handleSetComponentVisible = (visibleComponents) => {

    let isVisibleComponent = Object.assign([], isComponentVisible);

    isVisibleComponent.forEach((vc) => {
      if (
        visibleComponents.find((x) => x.description === vc.description) !==
        undefined
      ) {
        let isVisible = visibleComponents.find(
          (x) => x.description === vc.description
        ).visible;
        if (isVisible !== undefined) {
          vc.visible = isVisible;
        }
      }
    });

    setIsComponentVisible(isVisibleComponent);
  };

  const handleUploadFileSet = (setName) => {
    const fileIds = uploadedIds.current;

    fileSetUpload(patientId, setName, fileIds).then(({ id, setName }) => {
      const setId = id;
      const requiredFiles = [...selectedTreatment.requiredFiles];

      // aggregate the files
      const files = [];
      requiredFiles.forEach((section) => {
        // idx = index of section
        // idz = index of file in section.files
        const filesWithIndex = section.files.map((f, i) => ({ ...f, idz: i }));
        files.push(...filesWithIndex);
      });

      // update file in treatment details
      uploadedIds.current.forEach((uploadedId) => {
        const index = files.findIndex(
          (f) => f.patientFileExtensionId === uploadedId
        );
        const file = files[index];
        requiredFiles[file.idx].files[file.idz].set = setName;
        requiredFiles[file.idx].files[file.idz].patientFileId = setId;
      });

      const copy = [...treatmentsSelected];
      copy.requiredFiles = requiredFiles;
      updateSelectedTreatment(copy);

      // reset useRef values used for uploading a set
      fileCountToUpload.current = 0;
      uploadedIds.current = [];

      handleUploadChange(false);
    });
  };

  const handleUploadChange = (val) => {
    setUploading(val);
  };

  const openAdditionalFiles = (val) => {
    setAdditionalFilesModalOpen(val);
  };

  const cancelSubmission = () => {
    segment.trigger({
      event: "hub_cancel_order",
      properties: {
        products: selectedTreatment.map((treatment) => {
          return {
            product_name: treatment.description,
            product_category: treatment.treatmentCategoryDescription,
          };
        }),
      },
    });
    if (patientDetails.PrebookId) {
      navigate("/");
    }

    let patientDetailsObject = {
      Id: "",
      FirstName: "",
      LastName: "",
      Email: "",
      DateOfBirth: "",
      PracticeId: "",
      PracticeName: "",
      ReturnDate: "",
      isSameDayDentistry: false,
      isPrepOrFinalRestoration: false,
      IsRush: false,
      IsUsingGraphyWorkflow: "false",

      PersonnelId: "",
    };
    dispatch(updatePatientId({patientId: null}))
    handleNextStep(-1, [], patientDetailsObject);
  };

  const addHours = (numOfHours, date = new Date()) => {
    const dateCopy = new Date(date.getTime());

    dateCopy.setTime(dateCopy.getTime() + numOfHours * 60 * 60 * 1000);

    return dateCopy;
  };

  const proceed = () => {
    segment.trigger({
      event: "hub_finalize_order",
      properties: {
        products: selectedTreatment.map((treatment) => {
          return {
            product_name: treatment.description,
            product_category: treatment.treatmentCategoryDescription,
          };
        }),
      },
    });
    if (proceeding) {
      return;
    }
    setErrorMessage("");
    if (
      !userInfo.Roles.includes("Dentist") &&
      (!personnelId || personnelId === "")
    ) {
      setErrorMessage("Dentist is required for new patients");
      return;
    }

    if (
      (!patientId || patientId === "") &&
      patientsList.find(
        (patient) =>
          patient.firstName === firstName && patient.lastName === lastName
      )
    ) {
      setErrorMessage(
        "<div class='error-container'><b class='error-title'>Almost done!</b><p class='treatment-description'><span>Patient's first name and last name matches an existing patient.</span><a href='#patient-details'>Show fields</a></p></div>" 
      );
      return;
    }
    if (uploading) {
      setErrorMessage("Please wait for upload to finish.");
      return;
    }

    setErrorMessage("");

   

    let treatmentsSelectedCopy = Object.assign([], treatmentsSelected);
    if (Boolean(additionalOrderDetails?.notes)) {
      const notes = treatmentsSelectedCopy[0].additionalNotes;
      treatmentsSelectedCopy[0].additionalNotes = notes.concat(
        "\n\n" + additionalOrderDetails.notes
      );
    }

    let validRequirements = true;

    let requirementsCopy = [];
    treatmentsSelectedCopy.forEach((treat, idx) => {
      treat.requirements.forEach((req) => {
        let newreq = req;
        newreq.index = idx;
        requirementsCopy.push(newreq);
      });
    });
    let fieldsWithError = "";

    let isToothChartVisible = isComponentVisible.find(
      (x) => x.description === "Tooth Chart"
    )?.visible;

    let nonRequiredRequirements = [
      "Desired Base Thickness",
      "Desired Overjet & Overbite",
      "Provide Drawing Instructions (Optional)",
      "Model Files ($15 per additional file)",
      "Please indicate any restorations",
      "Desired Implant Depth (subcrestal)",
      "Mesial Occlusal Tooth #",
      "Distal Occlusal Tooth #",
      "Cingulum Tooth #",
      "Ball Tooth #",
      "Circumferential Tooth #",
      "I-Bar Tooth #",
      "T-Bar Tooth #",
      "RPA Tooth #",
      "RPI Tooth #",
      "Ring Tooth #",
      "Wrought Wire Tooth #",
      "Flexible Combo Tooth #",
    ];

    requirementsCopy.forEach((x) => {
      let reqIsVisible = isComponentVisible.find(
        (r) => r.description === x.description
      )?.visible;

      let isNotRequired =
        nonRequiredRequirements.find((r) => r === x.description) ===
        x.description;

      if (reqIsVisible === false || isNotRequired) {
      } else {
        if ((x.description === "Use ICAM/PIC Workflow" && x.value === "Yes") || (x.description === "Using Photogrammetry / OPTISPLINT Workflow?" && x.value === "ICAM/PIC")) {

          var re = /(?:\.([^.]+))?$/;
          let isIcamFound = false;
          treatmentsSelectedCopy[0].files.forEach((file) => {
            if (
              re.exec(file.fileName)[1] !== undefined &&
              re.exec(file.fileName)[1].toLowerCase() === "implantposition"
            ) {
              isIcamFound = true;
            }
          });
          if (isIcamFound === false) {
            validRequirements = false;
            fieldsWithError =
              fieldsWithError + "<p>Please Upload ImplantPosition File</p>";
          }
        }
        if (
          (x.description ===
            "Do you have an existing Smile Mock-up or Dentofacial Analysis case to follow?" ||
            x.description ===
              "Do you have an existing wax-up of denture design to follow?" ||
            x.description === "Do you have an existing wax-up to follow?") &&
          x.value === "Yes"
        ) {
          if (x.caseNumber === "" || x.caseNumber === null) {
            validRequirements = false;
            fieldsWithError =
              fieldsWithError +
              "<p>Please select case number in existing case</p>";
          }
        } else if (
          x.description !== "Tooth Chart" &&
          x.description !== "Type of Restoration (Multiple)" &&
          (x.value === null || (Array.isArray(x.value) && x.value.length === 0))
        ) {
          validRequirements = false;
          fieldsWithError = fieldsWithError + "<p class='treatment-description'><span>" + x.description + "</span><a href='#description-" + x.orderNumber +"'>Show fields</a></p>";
        
        } else {
          if (x.description === "Tooth Chart" && isToothChartVisible) {
            if (treatmentsSelectedCopy[x.index].toothNumbers.length === 0) {
              validRequirements = false;
              fieldsWithError = fieldsWithError + "<p class='treatment-description'><span> Tooth Chart </span><a href='#description-" + x.orderNumber +"'>Show fields</a></p>";
            }
          }
        }
      }
    });

    if (validRequirements === false && (firstName === "" ||
        lastName === "" ||
        practiceId === null ||
        practiceId === "")) {
  
      setErrorMessage(
        "<div class='error-container'><b class='error-title'>Almost done! Just fill in the * required info</b><p class='treatment-description'><span> Patient Details Info</span><a href='#patient-details'>Show fields</a></p>" + fieldsWithError + "</div>"
      );
      return;
    }
   else if (validRequirements === true && (firstName === "" ||
  lastName === "" ||
  practiceId === null ||
  practiceId === "")){
    setErrorMessage(
      "<div class='error-container'><b class='error-title'>Almost done!</b><p class='treatment-description'><span> Patient Details Info</span><a href='#patient-details'>Show fields</a></p></div>" 
    );
    return;
  }
    else if (validRequirements === false || (firstName === "" ||
    lastName === "" ||
    practiceId === null ||
    practiceId === "")){
      setErrorMessage(
        "<div class='error-container'><b class='error-title'>Almost done! Just fill in the * required info</b>" + fieldsWithError + "</div>"
      );
      return;
    }
    

   // if (treatmentsSelectedCopy[0].files.length === 0) {
    //   setErrorMessage("Please submit required files before proceeding.");
    //   return;
    // }

    let filesReq = selectedTreatment.requiredFiles.filter(
      (x) => x.name !== "Additional Files"
    );
    let isFileMissing = false;
    filesReq.forEach((fr) => {
      if (fr.files.length === 0) {
        isFileMissing = true;
      }
    });

    if (isFileMissing) {
      setErrorMessage(
        "<div class='error-container'><b class='error-title'>Almost done!</b><p class='treatment-description'><span>Please submit required files before proceeding.</span><a href='#required-files'>Show fields</a></p></div>"
        );
      return;
    }




    const isCerec = selectedTreatment.some((x) => x.productId === 1285);

    if (isCerec) {
      const dxdFiles = filesReq[0]?.files.some((file) =>
        file.fileName.includes(".dxd")
      );
      const isUploadDxdFile = selectedTreatment[0].requirements.some(
        (x) =>
          x.value === "Yes" &&
          x.description === "Will you be uploading a CEREC .dxd file?"
      );

      if (isUploadDxdFile && !dxdFiles) {
        setErrorMessage("Please submit a .dxd file.");
        return;
      }
    }

    let areToothChartAvailable = requirementsCopy.filter(
      (x) => x.description === "Tooth Chart"
    );

    const date = new Date();

    const result = addHours(12, date);

    setProceeding(true);
    if (areToothChartAvailable.length > 0 && isToothChartVisible) {
      const promises = areToothChartAvailable.map((requirement) => {
        return ToothChartService.convertToothChartToPng(
          treatmentsSelectedCopy[requirement.index].id
        );
      });

      Promise.all(promises).then((responses) => {
        areToothChartAvailable.forEach((requirement, i) => {
          treatmentsSelectedCopy[requirement.index].toothChartImage =
            responses[i];
        });

        setTreatmentsSelected(treatmentsSelectedCopy);

        let patientDetailsObject = {
          Id: patientId,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          DateOfBirth: birthday,
          PracticeId: practiceId,
          PracticeName: practiceList.find((p) => p.id === practiceId)?.name,
          ReturnDate: result,
          IsSameDayDentistry: isSameDayDentistry,
          IsPrepOrFinalRestoration: isPrepOrFinalRestoration,
          IsRush: isRush,
          isUsingGraphyWorkflow: isUsingGraphyWorkflow,
          PersonnelId: personnelId,
          IsPrebook: Boolean(patientDetails.PrebookId),
          PrebookId: patientDetails.PrebookId,
        };
        handleNextStep(
          1,
          treatmentsSelectedCopy,
          patientDetailsObject,
          null,
          isComponentVisible
        );
        setProceeding(false);
      });
    } else {
      treatmentsSelectedCopy[0].toothChartImage = null;
      setTreatmentsSelected(treatmentsSelectedCopy);

      let patientDetailsObject = {
        Id: patientId,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        DateOfBirth: birthday,
        PracticeId: practiceId,
        PracticeName: practiceList.find((p) => p.id === practiceId)?.name,
        ReturnDate: result,
        IsSameDayDentistry: isSameDayDentistry,
        IsPrepOrFinalRestoration: isPrepOrFinalRestoration,
        IsRush: isRush,
        isUsingGraphyWorkflow: isUsingGraphyWorkflow,
        PersonnelId: personnelId,
        IsPrebook: Boolean(patientDetails.PrebookId),
        PrebookId: patientDetails.PrebookId,
      };

      handleNextStep(
        1,
        treatmentsSelectedCopy,
        patientDetailsObject,
        null,
        isComponentVisible
      );
      setProceeding(false);
    }
  };


  const onSelectLastName = (value) => {
    if (value !== null) {
      setPatientId(value.id);
      setFirstName(value.firstName);
      setLastName(value.lastName);
      if (value.dateOfBirth !== null) {
        setBirthday(new Date(value.dateOfBirth));
      }
      setEmail(value.email);
      setPracticeId(value.practiceId);
      setPersonnelId(value.personnelId);


      PatientService.getAllMappedFiles(value.id)
          .then((response) => response.json())
          .then((json) => {
            setMappedFiles(json);
            setFilteredMappedFiles([]);
          });
      
      //setTreatmentsSelected(prev => prev.map(t => ({...t, files: []})));
    } else {
      setPatientId("");
      setFirstName("");
      setLastName("");
      setBirthday(null);
      setEmail("");
      // setReturnDate("");
      setMappedFiles([]);
      setFilteredMappedFiles([]);
    }
  };

  const handleSelectPractice = (selectedPracticeId) => {
    setFirstName("");
    setLastName("");
    setBirthday(null);
    setEmail("");
    // setReturnDate(null);
    setMappedFiles([]);
    setFilteredMappedFiles([]);
    setPracticeId(selectedPracticeId);
    setPersonnelId("");

    setPatientId("");
  };

  const handleTreatmentUpdate = (treatmentToUpdate) => {
    let treatmentsSelectedCopy = Object.assign([], treatmentsSelected);

    let getTreatmentToUpdate = treatmentsSelectedCopy.findIndex(
      (x) => x.id === treatmentToUpdate.id
    );

    if (getTreatmentToUpdate >= 0) {
      treatmentsSelectedCopy[getTreatmentToUpdate] = Object.assign(
        {},
        treatmentToUpdate
      );
      treatmentsSelectedCopy[getTreatmentToUpdate].toothNumbers = Object.assign(
        [],
        treatmentToUpdate.toothNumbers
      );

      treatmentsSelectedCopy[getTreatmentToUpdate].serviceProviderSelected =
        treatmentsSelected[0].serviceProviderSelected;

      setTreatmentsSelected(treatmentsSelectedCopy);
    }
  };

  const handleProductDetailModalClose = (confirm, treat) => {
    setTreatmentDetailToDisplayInModal(treat);
    setProductDetailModalOpen(confirm);
  };

  const handleSelectDentist = (id) => {
    setPersonnelId(id);
    setFirstName("");
    setLastName("");
    setBirthday(null);
    setEmail("");
    // setReturnDate(null);
    setMappedFiles([]);
    setFilteredMappedFiles([]);

    setPatientId("");
  };

  const handleAdditionalFilesModalClose = (confirm, selectedFiles) => {
    if (confirm) {
      selectedFiles.forEach((file) => {
        if (file.fileType !== 2 && file.fileType !== 3) {
          let newFile = {
            idx: selectedTreatment.requiredFiles.length - 1,
            fileName: file.fileName,
            fileId: file.patientFileId,
            patientFileId: file.id,
            uploadedDate: file.uploadedDate,
            fileType: file.fileTypeName,
          };
          var treatsToUpdate = [...treatmentsSelected];
          treatsToUpdate.requiredFiles = treatmentsSelected.requiredFiles;
          treatsToUpdate[0].files = treatsToUpdate[0].files.concat(newFile);
          var a = treatsToUpdate.requiredFiles.find(
            (x) => x.name == "Additional Files"
          );
          a.files.push(newFile);
          a.files.sort(
            (a, b) => Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
          );
        } else {
          let set = file.fileName;
          PatientService.getPatientFileExtension(
            file.patientFileId,
            file.patientId,
            file.fileType
          )
            .then((response1) => response1.json())
            .then((json1) => {
              json1.forEach((pFile) => {
                let newFile = {
                  idx: selectedTreatment.requiredFiles.length - 1,
                  fileName: pFile.fileName,
                  fileId: pFile.patientFileId,
                  patientFileId: file.id,
                  uploadedDate: pFile.uploadedDate,
                  set,
                  patientFileExtensionId: pFile.id,
                  fileType: file.fileTypeName,
                };
                var treatsToUpdate = [...treatmentsSelected];
                treatsToUpdate[0].files =
                  treatsToUpdate[0].files.concat(newFile);
                treatsToUpdate.requiredFiles = treatmentsSelected.requiredFiles;
                var a = treatsToUpdate.requiredFiles.find(
                  (x) => x.name == "Additional Files"
                );
                a.files.push(newFile);
                a.files.sort(
                  (a, b) =>
                    Date.parse(b.uploadedDate) - Date.parse(a.uploadedDate)
                );
                setTreatmentsSelected(treatsToUpdate);
              });
            });
        } //close else
      });
    }
    setAdditionalFilesModalOpen(false);
  };

  let rushButtonEnabled = true;
  if (
    dayandtimeChecker.day === "Sunday" ||
    dayandtimeChecker.day === "Saturday"
  ) {
    //disable rush button if weekends PST
    rushButtonEnabled = false;
  }

  const hideRush = treatmentsSelected.some((t) =>
    [
      "aligners",
      "clear retainers",
      "surgical planning and guide",
      "digital complete dentures",
      "digital partial dentures",
      "evident smile package",
      "all on x implants"
    ].includes(t.description.toLowerCase())
  );

  return (
    <>
      <Box sx={{ position: "relative", marginBottom: 16 }}>
        <SubHeader />
        <Box
          sx={{
            position: "absolute",
            top: "44px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "991px",
              paddingY: 3.5,
              paddingX: { xs: 4, md: 12 },
              marginX: "24px",
              backgroundColor: "white",
              borderRadius: "20px",
              textAlign: "center",
              boxShadow: "0px 13px 30px rgba(138, 138, 138, 0.1)",
              backdropFilter: "blur(10px)",
            }}
          >
            <Typography
            id="page-title"
              sx={{
                fontSize: "1.5em",
                color: brandBlack,
                fontWeight: "500",
              }}
            >
              Treatment Details
            </Typography>
            <Typography
              sx={{
                fontSize: "1em",
                marginTop: 1,
                color: "#0A0909",
                fontWeight: "400",
              }}
            >
              Fill your patient's information and provide treatment details
            </Typography>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="xl" sx={{ paddingX: { lg: 12 }, paddingBottom: 12 }}>
        <ProductDetailModal
          isOpen={productDetailModalOpen}
          handleCloseDialog={handleProductDetailModalClose}
          treatmentDetailToDisplay={treatmentDetailToDisplayInModal}
          productDetail={productDetail}
        />
        <AdditionalFilesModal
          requiredFiles={treatmentsSelected.requiredFiles}
          isOpen={additionalFilesModalOpen}
          handleClose={handleAdditionalFilesModalClose}
          mappedFiles={filteredMappedFiles}
        />

        {patientDetails.PrebookId && (
          <Alert severity="success" sx={{ marginBottom: 2 }}>
            Creating prebook case for {patientDetails.FirstName}{" "}
            {patientDetails.LastName}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconButton onClick={cancelSubmission}>
            <ArrowBackIcon fontSize="medium" sx={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="300"
            paddingBottom={0.5}
          >
            Back To Order Page
          </Typography>
        </Stack>
        <Divider
          sx={{
            border: "0.4px dashed #dad9d9",
            marginTop: 4,
            marginBottom: 8,
          }}
        />

        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderLeft: "1px dashed " + brandGray,
          }}
        >
          <PatientDetails
            treatment={selectedTreatment[0]}
            patientDetailsIcon={patientDetailsIcon}
            onSelectLastName={onSelectLastName}
            onSelectPractice={handleSelectPractice}
            patientsList={patientsList}
            practiceList={practiceList}
            lastName={lastName}
            patientId={patientId}
            firstName={firstName}
            birthday={birthday}
            practiceId={practiceId}
            email={email}
            returnDate={returnDate}
            isSameDayDentistry={isSameDayDentistry}
            isPrepOrFinalRestoration={isPrepOrFinalRestoration}
            setLastName={setLastName}
            setPatientId={setPatientId}
            setFirstName={setFirstName}
            setBirthday={setBirthday}
            setEmail={setEmail}
            setReturnDate={setReturnDate}
            setIsSameDayDentistry={setIsSameDayDentistry}
            setIsPrepOrFinalRestoration={setIsPrepOrFinalRestoration}
            dentists={dentistsList[practiceId] || []}
            personnelId={personnelId}
            onSelectDentist={handleSelectDentist}
            disableChange={
              Boolean(additionalOrderDetails?.updatedProductId) ||
              Boolean(patientDetails.PrebookId)
            }
          />
          {!hideRush && !Boolean(patientDetails.PrebookId) && (
            <Box
              sx={{
                background: brandLight,
                backdropFilter: " blur(20px)",
                borderRadius: "16px",
                marginLeft: "64px",
                // width: "90%",
                paddingTop: "45px",
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingBottom: "45px",
                marginBottom: "40px",
              }}
            >
              <Stack direction="row" alignItems="center">
                <Tooltip
                  title={
                    rushButtonEnabled === true
                      ? ""
                      : "Rush option is only available during Mondays to Fridays PST"
                  }
                >
                  <span>
                    <Checkbox
                      id="rush"
                      label="Rush Order ($5/unit or $25/case additional fee)"
                      inputProps={{
                        "aria-label":
                          "Rush Order ($5/unit or $25/case additional fee)",
                      }}
                      checked={isRush}
                      disabled={!rushButtonEnabled}
                      onChange={(e) => setIsRush(e.target.checked)}
                    />
                  </span>
                </Tooltip>
                <Box
                  component="label"
                  htmlFor="rush"
                  sx={{
                    fontSize: "21px",
                    fontWeight: "500",
                    lineHeight: "31px",
                    color: "#27374F",
                    paddingBottom: 0.5,
                  }}
                >
                  Rush Order ($5/unit or $25/case additional fee)
                </Box>
              </Stack>
            </Box>
          )}

          {isShowServiceProviders ? (
            <Box
              sx={{
                background: brandLight,
                backdropFilter: " blur(20px)",
                borderRadius: "16px",
                marginLeft: "64px",
                // width: "90%",
                paddingTop: "45px",
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingBottom: "45px",
                marginBottom: "40px",
              }}
            >
              <Box
                sx={{
                  fontSize: "21px",
                  fontWeight: "500",
                  lineHeight: "31px",
                  color: "#27374F",
                }}
              >
                Would you like the{" "}
                {treatmentsSelected[0].description === "Aligners"
                  ? "aligners"
                  : "retainers"}{" "}
                fabricated and shipped to you?
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    minWidth: "130px",
                    maxWidth: "200px",
                    cursor: "pointer",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                    margin: "10px",
                    height: "50px",
                    background:
                      treatmentsSelected[0].serviceProviderSelected ===
                      "Fabricate"
                        ? brandBlue
                        : "#FBFBFB",
                    color:
                      treatmentsSelected[0].serviceProviderSelected ===
                      "Fabricate"
                        ? "#fff"
                        : "#000",
                    borderRadius: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    let treatmentsSelectedCopy = Object.assign(
                      [],
                      treatmentsSelected
                    );

                    treatmentsSelectedCopy[0].serviceProviderSelected =
                      "Fabricate";

                    // let alignerIdx = treatmentsSelectedCopy
                    //   .map((e) => e.description)
                    //   .indexOf("Aligners");

                    // if (alignerIdx !== -1) {
                    //   treatmentsSelectedCopy[alignerIdx].requirements =
                    //     treatmentsSelectedCopy[alignerIdx].requirements.filter(
                    //       (x) =>
                    //         x.description !== "Please indicate any restorations"
                    //     );
                    // }

                    let newArr = isComponentVisible.map((item, i) => {
                      if (
                        item.description === "Hollow or Solid Models?" ||
                        item.description ===
                          "Do you need your models based for vertical printing?" ||
                        item.description === "Are you fabricating In-house?" ||
                        item.description ===
                          "What is the patient's main complaint?" ||
                        item.description ===
                          "The STL files I want to have are" ||
                        item.description ===
                          "Are you using the GRAPHY workflow?"
                      ) {
                        return {
                          ...item,
                          visible: false,
                        };
                      } else {
                        return item;
                      }
                    });

                    setIsComponentVisible(newArr);

                    handleTreatmentUpdate("Hollow or Solid Models?", null, []);
                    handleTreatmentUpdate(
                      "Do you need your models based for vertical printing?",
                      null,
                      []
                    );
                    handleTreatmentUpdate(
                      "Are you using the GRAPHY workflow?",
                      null,
                      []
                    );
                    handleTreatmentUpdate(
                      "Are you fabricating In-house?",
                      null,
                      []
                    );

                    handleSetComponentVisible(newArr);

                    setTreatmentsSelected(treatmentsSelectedCopy);

                    setShowGraphyWorkflow(false);
                    setIsUsingGraphyWorkflow(false);
                    graphyQuestions(false, "Fabricate");
                  }}
                >
                  Yes
                </Box>

                <Box
                  sx={{
                    minWidth: "130px",
                    maxWidth: "200px",
                    cursor: "pointer",
                    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                    margin: "10px",
                    height: "50px",
                    background:
                      treatmentsSelected[0].serviceProviderSelected === "Design"
                        ? brandBlue
                        : "#FBFBFB",
                    color:
                      treatmentsSelected[0].serviceProviderSelected === "Design"
                        ? "#fff"
                        : "#000",
                    borderRadius: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    let treatmentsSelectedCopy = Object.assign(
                      [],
                      treatmentsSelected
                    );

                    treatmentsSelectedCopy[0].serviceProviderSelected =
                      "Design";

                    // let alignerIdx = treatmentsSelectedCopy
                    //   .map((e) => e.description)
                    //   .indexOf("Aligners");

                    // if (alignerIdx !== -1) {
                    //   let newReq = {
                    //     componentType: null,
                    //     description: "Please indicate any restorations",
                    //     itemList: null,
                    //     orderNumber: 12,
                    //     value: null,
                    //   };

                    //   treatmentsSelectedCopy[alignerIdx].requirements.push(
                    //     newReq
                    //   );
                    // }

                    let newArr = isComponentVisible.map((item, i) => {
                      if (
                        item.description === "Hollow or Solid Models?" ||
                        item.description ===
                          "Do you need your models based for vertical printing?" ||
                        item.description === "Are you fabricating In-house?"
                      ) {
                        return {
                          ...item,
                          visible: true,
                        };
                      } else {
                        return item;
                      }
                    });
                    setIsComponentVisible(newArr);

                    handleTreatmentUpdate("Hollow or Solid Models?", null, []);
                    handleTreatmentUpdate(
                      "Do you need your models based for vertical printing?",
                      null,
                      []
                    );

                    handleTreatmentUpdate(
                      "Are you using the GRAPHY workflow?",
                      null,
                      []
                    );
                    handleTreatmentUpdate(
                      "Are you fabricating In-house?",
                      null,
                      []
                    );
                    handleSetComponentVisible(newArr);

                    setTreatmentsSelected(treatmentsSelectedCopy);
                    setShowGraphyWorkflow(true);
                    setIsUsingGraphyWorkflow(false);
                    graphyQuestions(false, "Design");
                  }}
                >
                  No
                </Box>
              </Box>
              {showGraphyWorkflow === true ? (
                <Box>
                  <Box
                    component="label"
                    htmlFor="GraphyWorkFlow"
                    sx={{
                      fontSize: "21px",
                      fontWeight: "500",
                      lineHeight: "31px",
                      color: "#27374F",
                      paddingBottom: 0.5,
                    }}
                  >
                    Are you using the GRAPHY workflow?
                    <IconButton
                      aria-describedby={"GraphyWorkFlowInfo"}
                      variant="contained"
                    >
                      <QuestionMark
                        fontSize="medium"
                        sx={{ color: brandBlue }}
                        onMouseEnter={() => {
                          setDisplayGraphyInfo(true);
                        }}
                        onMouseLeave={() => {
                          setDisplayGraphyInfo(false);
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        minWidth: "130px",
                        maxWidth: "200px",
                        cursor: "pointer",
                        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                        margin: "10px",
                        height: "50px",
                        background:
                          isUsingGraphyWorkflow === true
                            ? brandBlue
                            : "#FBFBFB",
                        color: isUsingGraphyWorkflow === true ? "#fff" : "#000",
                        borderRadius: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setIsUsingGraphyWorkflow(true);

                        graphyQuestions(true, "Design");
                      }}
                    >
                      Yes
                    </Box>
                    <Box
                      sx={{
                        minWidth: "130px",
                        maxWidth: "200px",
                        cursor: "pointer",
                        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                        margin: "10px",
                        height: "50px",
                        background:
                          isUsingGraphyWorkflow === false
                            ? brandBlue
                            : "#FBFBFB",
                        color:
                          isUsingGraphyWorkflow === false ? "#fff" : "#000",
                        borderRadius: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setIsUsingGraphyWorkflow(false);
                        graphyQuestions(false, "Design");
                      }}
                    >
                      No
                    </Box>
                    <Box
                      sx={{
                        display: displayGraphyInfo === true ? "block" : "none",
                        width: 400,
                      }}
                    >
                      Select Yes only if you are 3D printing your{" "}
                      {treatmentsSelected[0].description === "Aligners"
                        ? "aligners"
                        : "retainers"}{" "}
                      with Graphy resin and following the Graphy post processing
                      protocol.
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
          {/* {treatmentsSelected[0].serviceProviderSelected === "Design" && ( */}
          {/* <Box sx={{ marginTop: "45px", marginBottom: "45px" }}>
                <Box
                  sx={{
                    fontSize: "21px",
                    fontWeight: "500",
                    lineHeight: "31px",
                    color: "#27374F",
                  }}
                >
                  Please indicate any restorations
                </Box>
                <TextField
                  placeholder={"Please indicate any restorations"}
                  InputProps={{
                    style: { width: "100%", alignItems: "start" },
                  }}
                  sx={{ width: "50%", background: "#fff", marginTop: "5px" }}
                  value={
                    treatmentsSelected[0].requirements.find(
                      (x) => x.description === "Please indicate any restorations"
                    ) !== undefined
                      ? treatmentsSelected[0].requirements.find(
                          (x) =>
                            x.description === "Please indicate any restorations"
                        ).value
                      : ""
                  }
                  onChange={(e) => {
                    let treatmentToUpdate = treatmentsSelected.find(
                      (x) => x.description === "Aligners"
                    );

                    let treatmentsSelectedCopy = Object.assign(
                      [],
                      treatmentsSelected
                    );

                    let getTreatmentToUpdate = treatmentsSelectedCopy.findIndex(
                      (x) => x.id === treatmentToUpdate.id
                    );

                    if (getTreatmentToUpdate >= 0) {
                      treatmentsSelectedCopy[getTreatmentToUpdate] =
                        Object.assign({}, treatmentToUpdate);

                      let getRequirement = treatmentsSelecte
                        getTreatmentToUpdate
                      ].requirements.find(
                        (x) =>
                          x.description === "Please indicate any restorations"
                      );

                      if (typeof getRequirement !== "undefined") {
                        getRequirement.value = e.target.value;
                      } else {
                        let newReq = {
                          componentType: null,
                          description: "Please indicate any restorations",
                          itemList: null,
                          orderNumber: 12,
                          value: e.target.value,
                        };

                        treatmentsSelectedCopy[
                          getTreatmentToUpdate
                        ].requirements.push(newReq);

                        setTreatmentsSelected(treatmentsSelectedCopy);
                      }

                      setTreatmentsSelected(treatmentsSelectedCopy);
                    }
                  }}
                />
              </Box> */}
          {/* )} */}

          {treatmentsSelected.map((treatment, index) => (
            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  height: "57px",
                  paddingTop: 1.5,
                }}
              >
                <Box>
                  <Divider
                    sx={{
                      border: "1px dashed #969696",
                      width: "64px",
                      height: "0px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "55px",
                    height: "55px",
                    padding: 1,
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: "32px",
                    lineHeight: "31px",
                    position: "relative",
                    top: "-28px",
                  }}
                >
                  <img src={treatment.imageLink} style={{ width: "100%" }} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-28px",
                    marginLeft: "20px",
                  }}
                >
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={4}
                  >
                    <Box>
                      <Box
                        sx={{
                          fontSize: "24px",
                          color: "#21253A",
                          lineHeight: "31px",
                          fontWeight: "500",
                        }}
                      >
                        {treatment.description}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          color: "#C0C0C0",
                          marginTop: "7px",
                        }}
                      >
                        {/* <Link
                          target="_blank"
                          href={treatment.productSubDescription}
                        >
                          {treatment.productSubDescription}
                        </Link> */}
                      </Box>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <StyledButton
                        variant="primary"
                        onClick={() =>
                          handleProductDetailModalClose(true, treatment)
                        }
                      >
                        About This Treatment
                      </StyledButton>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: "64px",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "64px",
                }}
              >
                <Box
                  sx={{
                    background: brandLight,
                    borderRadius: "20px",
                    boxShadow: "0px 5px 10px rgba(138, 138, 138, 0.03)",
                    minHeight: "500px",
                  }}
                >
                  <Box sx={{ paddingX: "57px", paddingY: "37px" }}>
                    <TreatmentForms
                      isCancelled={isCancelled}
                      treatment={treatment}
                      patientId={patientId}
                      handleTreatmentUpdate={handleTreatmentUpdate}
                      notion={notion}
                      isComponentVisible={isComponentVisible}
                      setIsComponentVisible={handleSetComponentVisible}
                      personnelId={personnelId}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          ))}

          {/* {errorMessage !== "" ? (
            <div
              style={{ textAlign: "center", fontSize: "20px", color: "red" }}
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          ) : (
            ""
          )} */}

          <Box sx={{ display: "flex", alignItems: "start" }}>
            <Box>
              <Divider
                sx={{
                  border: "1px dashed #969696",
                  width: "64px",
                }}
                flexItem={true}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "60px" }}
              >
                <Box
                  sx={{
                    width: "55px",
                    height: "55px",
                    padding: 1,
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#656565",
                    fontSize: "32px",
                    lineHeight: "31px",
                    position: "relative",
                    top: "-28px",
                  }}
                >
                  <BackupIcon />
                </Box>
                <Box
                  id="required-files"
                  sx={{
                    fontSize: "24px",
                    color: "#21253A",
                    lineHeight: "31px",
                    fontWeight: "500",
                    position: "relative",
                    top: "-28px",
                    marginLeft: "20px",
                  }}
                >
                  Required Files
                </Box>
              </Box>
              <FilesComponent
                requiredFiles={selectedTreatment}
                onFileChange={onFileChange}
                removeFile={removeFile}
                uploading={uploading}
                openAdditionalFiles={openAdditionalFiles}
                showSelect={patientId !== ""}
              />
            </Box>
          </Box>
          {errorMessage !== "" ? (
            <div
              style={{ textAlign: "center", fontSize: "20px", color: "red" }}
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          ) : (
            ""
          )}

          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box>
              <Divider
                sx={{
                  border: "1px dashed #969696",
                  width: "64px",
                }}
                flexItem={true}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <LargeButton variant="gray" onClick={cancelSubmission}>
                  <Box component="span" sx={{ paddingX: 6 }}>
                    Cancel
                  </Box>
                </LargeButton>
                <LargeButton id="proceed"  variant="primary" onClick={proceed}>
                  <Box component="span" sx={{ paddingX: 6 }}>
                    Proceed
                  </Box>
                </LargeButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default connect(mapStateToProps, null)(InputTreatmentDetailsBeta);
