import {
  Box,
  Container,
  IconButton,
  Stack,
  Typography,
  Avatar,
  Grid,
  List,
  ListItem,
  Skeleton,
  Button,
} from "@mui/material";
import React from "react";
import Header from "../../components/header/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import LargeButton from "../../components/common/button/largeButton";
import { useEffect } from "react";
import { useState } from "react";
import PatientService from "../../services/patient.service";
import moment from "moment";
import { Link as RouterLink, NavLink, useParams } from "react-router-dom";
import IntraoralScans from "../../components/patient/intraoralScans";
import ContentContainer from "../../components/common/contentContainer";
import ClinicialPhotos from "../../components/patient/clinicalPhotos";
import CBCTImages from "../../components/patient/cbctImages";
import DigitalPrescription from "../../components/patient/digitalPrescription";
import CompletedSurgicalPlan from "../../components/patient/completedSurgicalPlan";
import CompletedDesigns from "../../components/patient/completedDesigns";
import PatientDetailsDialog from "../../components/patient/patientDetailsDialog";
import { brandBlue, brandLight } from "../../constants";

const Patient = () => {
  const [patient, setPatient] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const { patientId, fileType } = useParams();

  useEffect(() => {
    let mounted = true;
    const getPatient = async () => {
      const response = await PatientService.getBasicPatientInfo(patientId);
      const data = await response.json();
      if (mounted) {
        setPatient(data);
      }
    };

    getPatient();
    return () => {
      mounted = false;
    };
  }, [patientId]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const parentPath = `/patients/${patientId}/`;
  const navLinks = [
    { path: parentPath + `intraoral-scans`, name: "Intraoral Scans" },
    { path: parentPath + `clinical-photos`, name: "Clinical Photos" },
    { path: parentPath + `cbct-images`, name: "CBCT Images" },
    { path: parentPath + `digital-prescription`, name: "Digital Prescription" },
    {
      path: parentPath + `completed-surgical-plan`,
      name: "Completed Surgical Plan STL",
    },
    {
      path: parentPath + `completed-designs`,
      name: "Completed Designs",
    },
  ];

  const selectedFileType = fileType || "intraoral-scans";
  let content;
  switch (selectedFileType) {
    case "intraoral-scans":
      content = <IntraoralScans patientId={patientId} />;
      break;
    case "clinical-photos":
      content = <ClinicialPhotos patientId={patientId} />;
      break;
    case "cbct-images":
      content = <CBCTImages patientId={patientId} />;
      break;
    case "digital-prescription":
      content = <DigitalPrescription patientId={patientId} />;
      break;
    case "completed-surgical-plan":
      content = <CompletedSurgicalPlan patientId={patientId} />;
      break;
    case "completed-designs":
      content = (
        <CompletedDesigns
          patientId={patientId}
          personnelId={patient.personnelId}
        />
      );
      break;
    default:
      content = "File type does not exist";
  }

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleEditSuccess = ({
    firstName,
    lastName,
    dateOfBirth,
    profilePictureUrl,
  }) => {
    setPatient({
      ...patient,
      firstName,
      lastName,
      dateOfBirth,
      profilePictureUrl,
      fullName: `${lastName}, ${firstName}`,
    });
  };

  return (
    <>
      <Header activeTab="/patients" />
      <Container maxWidth="xl">
        <Grid container marginY={5}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box maxWidth="md">
                <Stack direction="row" alignItems="center" paddingBottom={1}>
                  <IconButton component={RouterLink} to="/patients">
                    <ArrowBackIcon fontSize="large" sx={{ color: "black" }} />
                  </IconButton>
                  <Typography variant="h4" component="h1" fontWeight="300">
                    Manage Patient Files
                  </Typography>
                </Stack>
                <Typography variant="body2" marginY={0} paddingBottom={2}>
                  {/* No content */}
                </Typography>
              </Box>
              {/* <LargeButton variant="outlined">Patient List</LargeButton> */}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box
              paddingX={5}
              paddingY={3.5}
              marginTop={2}
              marginBottom={1.5}
              borderRadius="20px"
              sx={{ bgcolor: brandLight }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={2.5} alignItems="center">
                  {Object.keys(patient).length === 0 ? (
                    <React.Fragment>
                      <Skeleton
                        variant="circular"
                        width="120px"
                        height="120px"
                      />
                      <Box
                        alignSelf="start"
                        sx={{ fontSize: "1.8em", px: 0.5 }}
                      >
                        <Skeleton
                          variant="text"
                          sx={{
                            width: `${getRandomInt(300, 500)}px`,
                          }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{
                            width: `${getRandomInt(200, 300)}px`,
                          }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{
                            width: `${getRandomInt(150, 300)}px`,
                          }}
                        />
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Avatar
                        sx={{ height: "120px", width: "120px" }}
                        src={patient.profilePictureUrl}
                      />
                      <Stack spacing={1} sx={{ fontSize: "1.3em" }}>
                        <Box component="p" my={0} sx={{ px: 0.5 }}>
                          <Box
                            component="span"
                            fontWeight="500"
                            color="#5B5B5B"
                            marginRight={1}
                          >
                            Patient Name:
                          </Box>
                          {patient.fullName}
                        </Box>
                        <Box component="p" my={0} sx={{ px: 0.5 }}>
                          <Box
                            component="span"
                            fontWeight="500"
                            color="#5B5B5B"
                            marginRight={1}
                          >
                            Birthday:
                          </Box>
                          {patient.dateOfBirth
                            ? moment(patient.dateOfBirth).format("LL")
                            : "Not set"}
                        </Box>
                        <Box>
                          <Button
                            startIcon={<BorderColorOutlinedIcon />}
                            sx={{
                              fontSize: "0.8em",
                              textTransform: "none",
                              fontWeight: "bold",
                              color: brandBlue,
                            }}
                            onClick={handleOpenEditDialog}
                          >
                            Edit Patient Details
                          </Button>
                        </Box>
                      </Stack>
                    </React.Fragment>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  {Object.keys(patient).length > 0 && (
                    <>
                      <LargeButton
                        variant="secondary"
                        component={RouterLink}
                        to={
                          "/cases?search=" +
                          `${patient.firstName} ${patient.lastName}`
                        }
                      >
                        Track Cases Progress
                      </LargeButton>
                      <LargeButton
                        variant="primary"
                        component={RouterLink}
                        to={"/?patientId=" + patientId}
                      >
                        Add New Treatment
                      </LargeButton>
                    </>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Stack
            width="100%"
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            alignItems="flex-start"
          >
            <ContentContainer
              sx={{
                minWidth: { xs: "100%", md: "318px" },
                position: "sticky",
                top: 0,
                paddingX: 0,
                paddingTop: 0,
                paddingBottom: { xs: 4, md: 20 },
                backgroundColor: brandLight,
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ paddingTop: 5, paddingBottom: 2 }}
                >
                  Select a File Type:
                </Typography>

                <List sx={{ width: "100%" }}>
                  {navLinks.map((navLink) => (
                    <ListItem
                      button
                      disableGutters
                      to={navLink.path}
                      component={NavLink}
                      key={navLink.path}
                      sx={{
                        width: "100%",
                        paddingY: 2,
                      }}
                      style={({ isActive }) =>
                        isActive
                          ? {
                              borderLeft: "7px solid " + brandBlue,
                              paddingRight: "7px",
                              fontWeight: "bold",
                              backgroundColor: "#EBECF0",
                              boxShadow:
                                "inset 0px 4px 4px rgba(0, 0, 0, 0.15)",
                            }
                          : undefined
                      }
                    >
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {navLink.name}
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </ContentContainer>

            <Box width="100%">{content}</Box>
          </Stack>
        </Grid>
      </Container>
      <PatientDetailsDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onSuccess={handleEditSuccess}
        patient={patient}
        patientId={patientId}
        practiceId={patient.practiceId}
      />
    </>
  );
};

export default Patient;
