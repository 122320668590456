import { useState, useEffect } from "react";
import { connect } from "react-redux";

import Header from "../../components/header/header";
import SelectCases from "../../components/caseCreation/selectCases/selectCases";
import InputTreatmentDetails from "../../components/caseCreation/inputTreatmentDetails/inputTreatmentDetails";
import PaymentDetails from "../../components/caseCreation/paymentDetails/paymentDetails";
import CaseConfirmationPage from "../../components/caseCreation/caseConfirmationPage/caseConfirmationPage";

import { openPrebookDialog, updateDisplayTheme, updatePatient, updatePatientId, updateUseCurrentScan } from "../../redux/actions/index";

import AdminService from "../../services/admin.service";

import InputTreatmentDetailsBeta from "../../components/caseCreation/inputTreatmentDetails/inputTreatmentDetailsBeta";
import PatientService from "../../services/patient.service";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Layout from "../../components/common/layout";
import { useLogin } from "../../hooks/useLogin";
import { useDispatch, useSelector } from "react-redux";
import BillingOptionModal from "../../components/account/billingOptionModal";

import { initUser, openLoading, closeLoading, updateProductToAdd } from "../../redux/actions";
import { removeParameterFromUrl } from "../../helpers/removeParameterFromUrl";
import { Portal } from "@mui/material";
import ProductDetailModal from "../../components/caseCreation/inputTreatmentDetails/productDetailModal/productDetailModal";
import ConfirmIfNewScanModal from "../../components/caseCreation/inputTreatmentDetails/productDetailModal/confirmScanModal";
import CaseService from "../../services/case.service";
import setFilePhoto from '../../images/set_file_photo.svg';
import AdditionalFiles from "../../images/additionalFiles.svg";
import { fileNestingProduct } from "../../constants/specialProducts";
import LoadingModal from "../../components/common/modal/loadingModal";

function mapStateToProps(state) {
  return { displayTheme: state.displayTheme };
}

function mapDispatchToProps(dispatch) {
  return {
    updateDisplayTheme: (newTheme) => dispatch(updateDisplayTheme(newTheme)),
  };
}

function Main({ noLayout, updateDisplayTheme, displayTheme }) {
  const [isCancelled, setIsCancelled] = useState(false);
  const [treatmentStep, setTreatmentStep] = useState(1);

  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const patientId = searchParams.get("patientId");
  const otlid = searchParams.get("otlid");
  const sessionid = searchParams.get("sessionid");
  const params = searchParams.get('params');

  const [componentsVisible, setComponentsVisible] = useState([]);
  const { getTreatments } = AdminService;
  const [additionalOrderDetails, setAdditionalOrderDetails] = useState([]);

  const dispatch = useDispatch();

  const { ssologin } = useLogin();
  const userInfo = useSelector((state) => state.userInfo) || {};
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const [firstCheckingOfBilling, setFirstCheckingOfBilling] = useState(false);
  const [userBillingOption, setUserBillingOption] = useState("");
  const [productsToShow,setProductsToShow] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [productDetailModalOpen, setProductDetailModalOpen] = useState(false);
  const [treatmentDetailToDisplayInModal, setTreatmentDetailToDisplayInModal] = useState([]);
  const [openConfirmIfNewScanModal,setOpenConfirmIfNewScanModal] = useState(false);
  const navigate = useNavigate();

  const handleProductDetailModalToggle = (confirm, treat) => {
    setTreatmentDetailToDisplayInModal(treat);
    setProductDetailModalOpen(confirm);
  };

  const upsellProductToAdd = useSelector((state) => state.productToAdd);
  const currentPatient = useSelector(state=>state.patient);

  useEffect(()=>{
    const params = searchParams.get('params');
    if(!Object.keys(userInfo).length == 0 && params && params?.trim() !== '' && params !== 'null'){
        const stringified = window.atob(params);
        const paramsObject = JSON.parse(stringified);
        if(paramsObject.paymentDetailsUpdateRequired){
            navigate('/paymentDetails/?identifier=failedPayment');
            removeParameterFromUrl(window.location.href,'params');
        }
    }
  },[userInfo,params]);

  const handleCancelClick = () => {
    setIsCancelled(true);
    console.log("cancel button is clicked");
  };


  function IterateProducts(obj, callback) {
    for (const [key, value] of Object.entries(obj)) {
      if (value && typeof value === "object") {
        // Recurse
        IterateProducts(value, callback);
      } else {
        callback(key, value);

        if (key === "itemList" && value !== null) {
          obj[key] = JSON.parse(value);
        }
        if (key === "componentType" && value === "CheckboxListComponent") {
          obj["value"] = [];
        }
        if (
          key === "description" &&
          value === "Select up to 4 files to be included in the package"
        ) {
          obj["value"] = [];
          obj["valueid"] = [];
        }
        if (key === "description" && value === "Desired Overjet & Overbite") {
          obj["value"] = "";
        }
        if (key === "description" && value === "Desired Base Thickness") {
          obj["value"] = "";
        }
      }
    }
  }

  function handleBillingOptionClose() {
    setOpenBillingModal(false);
  }

  function handleSelectBillingOption(selectedBillOption) {
    setUserBillingOption(selectedBillOption);
    localStorage.setItem('locBillingOption', selectedBillOption);
  }

  const updateProductsToShow = (productsList) => {
    setProductsToShow(productsList);
  }

  useEffect(() => {
    getTreatments()
      .then((response) => response.json())
      .then((json) => {
        if (patientId) {
          PatientService.getBasicPatientInfo(patientId)
            .then((response) => {
              if (response.ok) return response.json();
              throw "Error";
            })
            .then((patient) => {
              setPatientDetails({
                FirstName: patient.firstName,
                LastName: patient.lastName,
                Email: patient.email,
                Id: patientId,
                DateOfBirth: patient.dateOfBirth,
                PracticeId: patient.practiceId,
                PersonnelId: patient.personnelId,
              });
              setTreatments(json[0].products);
            })
            .catch((err) => {
              setPatientDetails({
                FirstName: "",
                LastName: "",
                Email: "",
                Id: "",
                DateOfBirth: "",
                PracticeId: "",
                PersonnelId: "",
              });
              setSearchParams({});
            });
        } else {
          setTreatments(json[0].products);
        }

        let prods = IterateProducts(json[0].products, (key, value) => {});
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  useEffect(() => {
    if (otlid !== null && sessionid !== null) {
      dispatch(closeLoading());
      ssologin(otlid, sessionid);
      setSearchParams({params});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otlid]);

  useEffect(() => {
    if (location.state && location.state.selectedTreatment) {
      setSelectedTreatment(location.state.selectedTreatment);
      setPatientDetails({
        Id: "",
        FirstName: "",
        LastName: "",
        Email: "",
        DateOfBirth: null,
        PracticeId: "",
        PersonnelId: "",
      });
      setTreatmentStep(2);
    }

    // additional order details
    if (location.state && location.state.additionalOrderDetails) {
      setAdditionalOrderDetails(location.state.additionalOrderDetails);
    }
  }, [location.state]);

  const handleNextStep = (
    step,
    selectedTreatmentType,
    patientDetails = null,
    confirmationDetails = null,
    isComponentVisible = null
  ) => {

    setSelectedTreatment(selectedTreatmentType);
    setPatientDetails(patientDetails);
    setTreatmentStep((treatmentStep) => treatmentStep + step);
    setConfirmationDetails(confirmationDetails);

    setComponentsVisible(isComponentVisible);

    window.scrollTo(0, 0);
    if (patientId) {
      setSearchParams({});
    }
    if (treatmentStep + step === 1) {
      setAdditionalOrderDetails(null);
    }
  };

  useEffect(() => {
    AdminService.getProductProfiles()
      .then((response) => response.json())
      .then((json) => {
        setProductDetail(json);
      });
  }, []);

  const clickOrderNowHandler = (product,patient) => {
    dispatch(updatePatientId(patient.patientId));
    patient.IsRush = false;
    dispatch(updatePatient(patient));
    dispatch(updateProductToAdd(product));

    if(product.id === process.env.REACT_APP_FILE_NESTING_ID){
      const prodToAdd = [product]
      setSelectedTreatment(prodToAdd);
      setPatientDetails(currentPatient);
      navigate('/');
      setTreatmentStep(3);
      window.scrollTo(0,0)
    }

    if(product.id === 'prebooking'){
      navigate('/');
      setTreatmentStep(1);
      dispatch(openPrebookDialog(true));
    }

    if(product.id !== process.env.REACT_APP_FILE_NESTING_ID && product.id !== 'prebooking'){
      setOpenConfirmIfNewScanModal(true);
      navigate('/');
    }
  }

  async function getRequiredFiles(treatment) {
    let reqFiles = null;
    await CaseService.getRequiredFiles([treatment])
      .then((response) => response.json())
      .then((json) => {
        if(treatment === "CEREC Crown & Bridge"){
          json[0].name = "Drag & Drop your CEREC .dxd file here";
        }
        reqFiles = json;

        reqFiles.forEach((req) => {
          req.image = setFilePhoto;
        });

        reqFiles.push({
          name: "Additional Files",
          image: AdditionalFiles,
          files: [],
          numberOfFilesUploading: 0,
        });
      });

    return reqFiles;
  }

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const orderUpsellItemHandler = async (useCurrentScans) => {
    dispatch(updateUseCurrentScan(useCurrentScans));
    setOpenConfirmIfNewScanModal(false);
  
    const prodToAdd = [upsellProductToAdd]
    let requiredF = [];
    for (const treat of prodToAdd) {
      var reqFiles = await getRequiredFiles(treat.description);
      reqFiles.forEach((req) => {
        requiredF.push(req);
      });
    }
    reqFiles.forEach((req) => {
      requiredF.push(req);
    });
    prodToAdd.requiredFiles = getUnique(requiredF, "name");

    setSelectedTreatment(prodToAdd);
    setPatientDetails(currentPatient);
    navigate('/');
    setTreatmentStep(2);
    window.scrollTo(0,0)
  }

  const closeConfirmIfNewScanModal = () => {
    setOpenConfirmIfNewScanModal(false);
  }

  const handleAddAdditional = async (newTreatmentId) => {
    const newSelectedTreatment = [...selectedTreatment];
    let justNestingFile = false;
    if(newTreatmentId === process.env.REACT_APP_FILE_NESTING_ID){
      const nestingProduct = fileNestingProduct;
      nestingProduct.requirements = []
      newSelectedTreatment.push(nestingProduct);
      justNestingFile = true;
    } else {
      const newTreatment = productsToShow.find((p) => p.id === newTreatmentId);
      newSelectedTreatment.push(newTreatment)
    }
  
    if (justNestingFile) {
      newSelectedTreatment.requiredFiles = selectedTreatment.requiredFiles;
      setSelectedTreatment(newSelectedTreatment);
      navigate('/')
      setTreatmentStep(3);
      window.scrollTo(0,0)
      return
    }

    let requiredF = [];
    for (const treat of newSelectedTreatment) {
      var reqFiles = await getRequiredFiles(treat.description);
      reqFiles.forEach((req) => {
        requiredF.push(req);
      });
    }

    reqFiles.forEach((req) => {
      requiredF.push(req);
    });

    const oldRequiredFiles = selectedTreatment.requiredFiles;
    
    newSelectedTreatment.requiredFiles = getUnique(requiredF, "name");
    newSelectedTreatment.requiredFiles.forEach((req) => {
      const oldReq = oldRequiredFiles.find((o) => o.name === req.name);
      if (oldReq) {
        req.files = oldReq.files;
      }
    });
    console.log(newSelectedTreatment);

    setSelectedTreatment(newSelectedTreatment);
    navigate('/')
    setTreatmentStep(2);
    window.scrollTo(0,0)
  }

  let component = "";

  if (treatmentStep === 1) {
    component = (
      <SelectCases
        onClickAboutTreatment={handleProductDetailModalToggle}
        productDetail={productDetail}
        treatments={treatments}
        patientDetails={patientDetails}
        handleNextStep={handleNextStep}
        additionalOrderDetails={additionalOrderDetails}
        onUpdatingProductsToShow={updateProductsToShow}
        productsToShow={productsToShow}
      />
    );
  } else if (treatmentStep === 2) {
    component = (
      <InputTreatmentDetailsBeta
        isCancelled={isCancelled}
        selectedTreatment={selectedTreatment}
        handleNextStep={handleNextStep}
        patientDetails={patientDetails}
        setSelectedTreatment={setSelectedTreatment}
        additionalOrderDetails={additionalOrderDetails}
      />
    );
  } else if (treatmentStep === 3) {
    component = (
      <PaymentDetails
        buttonCancel={handleCancelClick}
        selectedTreatment={selectedTreatment}
        patientDetails={patientDetails}
        handleNextStep={handleNextStep}
        treatmentsMaster={treatments}
        componentsVisible={componentsVisible}
        additionalOrderDetails={additionalOrderDetails}
        productsToShow={productsToShow}
        onAddAdditional={handleAddAdditional}
        onClickAboutTreatment={handleProductDetailModalToggle}
      />
    );
  } else if (treatmentStep === 4) {
    component = (
      <CaseConfirmationPage
        onClickAboutTreatment={handleProductDetailModalToggle}
        productDetail={productDetail}
        selectedTreatment={selectedTreatment}
        patientDetails={patientDetails}
        confirmationDetails={confirmationDetails}
        handleNextStep={handleNextStep}
        productsToShow={productsToShow}
        onClickOrderNow={clickOrderNowHandler}
      />
    );
  }

  return (
    <div style={{ height: "100%" }}>
      
      {noLayout ? (
        <>
          <Header activeTab="/" />
          {component}
        </>
      ) : (
        <Layout>
          <Header activeTab="/" />
          {component}
        </Layout>
      )}
      <Portal>
        <ProductDetailModal
          isOpen={productDetailModalOpen}
          handleCloseDialog={handleProductDetailModalToggle}
          treatmentDetailToDisplay={treatmentDetailToDisplayInModal}
          productDetail={productDetail}
        />
      </Portal>
      <Portal>
        <ConfirmIfNewScanModal
          isOpen={openConfirmIfNewScanModal}
          closeDialog={closeConfirmIfNewScanModal}
          confirmNewOrder={orderUpsellItemHandler}
        />
      </Portal>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
