import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { TextField, InputAdornment, MenuItem, Checkbox } from "@mui/material";
import { brandBlue } from "../../constants";
import { useDispatch } from "react-redux";
import CaseService from "../../services/case.service";
import { Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';

import { openSnackbar } from "../../redux/actions";
function ListWithChildComponent(props) {
  const {
    req,
    handleRequirementParentSelectValue,
    handleRequirementChildSelectValue,
    patientId,
    personnelId,
    additionalOrderDetails,
    isAllOnXPackage = false,
    isCancelled
  } = props;

  const dispatch = useDispatch();

  const [caseNumbers, setCaseNumbers] = useState([]);

  if(req.description === 'Treatment Requirement' && isAllOnXPackage){
    req.value = "Both Temp and Final";
  }


  const getCasesByType = async (req, child) => {};

  const [inputValue, setInputValue] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);

  const handleBlur = () => {
    setIsEmpty(inputValue.trim() === '');
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.trim() !== '') {
      setIsEmpty(false);
    }
  };

  const location = useLocation();

  React.useEffect(() => {
    function handleRouteChange() {
      console.log('Route changed to:', location, location.pathname);
      // Trigger your function here
      const event = { target: { value: isCancelled ? 'Some initial value' : '' } };
      handleChange(event);
    
    }

    handleRouteChange();
  }, [location]);

 

  const LoadCaseNumbers = () => {
    req.itemList.forEach((row) => {
      if (req.value === row?.item) {
        row.child?.forEach((childRow) => {
          if (childRow?.type === "caseNumber") {
            if (patientId === "") {
              handleRequirementParentSelectValue(
                req.description,
                "No",
                req.itemList
              );
              setCaseNumbers([]);
            } else {
              console.log("toooo", "too");
              const getCaseNumbers = async () => {
                const response = await CaseService.getCaseNumbersByProduct(
                  childRow.productIds,
                  personnelId,
                  patientId
                );
                const data = await response.json();
                let caseExists = false;
                data?.forEach((d) => {
                  if (d === childRow.caseNumber) {
                    caseExists = true;
                  }
                });

                if (caseExists === false) {
                  handleRequirementChildSelectValue(
                    req.description,
                    childRow.name,
                    ""
                  );
                }
                if (data.length === 0) {
                  let message = "";
                  if (
                    req.description ===
                    "Do you have an existing Smile Mock-up or Dentofacial Analysis case to follow?"
                  ) {
                    message =
                      "There is no Smile Mock-up or Dentofacial Analysis case for the patient yet";
                  } else if (
                    req.description ===
                    "Do you have an existing wax-up of denture design to follow?"
                  ) {
                    message =
                      "There is no wax-up of denture design case for the patient yet";
                  } else if (
                    req.description ===
                    "Do you have an existing wax-up to follow?"
                  ) {
                    message = "There is no wax-up case for the patient yet";
                  }

                  dispatch(
                    openSnackbar({
                      title: "Error",
                      body: message,
                      type: "warning",
                    })
                  );

                  handleRequirementParentSelectValue(
                    req.description,
                    "No",
                    req.itemList
                  );
                }
                setCaseNumbers(data);
              };
              getCaseNumbers();
            }
          }
        });
      }
    });
  };


  useEffect(() => {
    LoadCaseNumbers();
  }, [patientId]);


  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {req.itemList.map((row, index) => (
        <Box
         
          key={index}
          sx={{
            textAlign: "center",
            width: "230px",
            cursor: "pointer",
            marginRight: "15px",
            height: "55px",
            background: req.value === row.item ? brandBlue : "#FBFBFB",
            color: req.value === row.item ? "#fff" : "#000",
            borderRadius: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderColor: req.value === row.item ? "transparent" : "#e0e0e0",
          }}
          onClick={() => {
            handleRequirementParentSelectValue(
              req.description,
              row.item,
              req.itemList
            );
            if (
              (req.description ===
                "Do you have an existing Smile Mock-up or Dentofacial Analysis case to follow?" ||
                req.description ===
                  "Do you have an existing wax-up of denture design to follow?" ||
                req.description ===
                  "Do you have an existing wax-up to follow?") &&
              req.item !== "No"
            ) {
            LoadCaseNumbers();
            }
            if (
              (req.description ===
                "Treatment Requirement") && req.item !== "Final Restoration (Zirconia) Only"
            ){
              if (Boolean(additionalOrderDetails?.notes)){
                handleRequirementParentSelectValue(
                  req.description,
                  "Final Restoration (Zirconia) Only",
                  req.itemList
                );
              }
            }
            // setIndexHighlight(index);
          }}
        >
          {row.item}
        </Box>
      ))}

      {req.itemList.map((row, index) => (
        <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            {req.value === row?.item ? (
              <Box sx={{ display: "flex", position: "relative" }}>
                          {row.child?.map((childRow, childIndex) =>
                              childRow?.type === "textfield" && (childRow.name==="material"||childRow.name==="printerModel") ? (
                    <>
                     <Typography
                    color={isEmpty ? 'red' : 'textPrimary'}
                    marginLeft={1}
                    sx={{float:"right", fontSize:"13px",position:"absolute",top:"-50px"}}
                  >
                    {isEmpty ? <span className="error-highlight">Please fill out the missing fields.</span> : ' '}
                  </Typography>
                    <TextField
                      autoFocus
                      type={childRow?.isNumber ? "number" : ""}
                      key={childIndex}
                      size={"medium"}
                      value={req[childRow.name]}
                      onBlur={handleBlur}
                      isEmpty={isEmpty}
                      onChange={(e) => {
                        handleRequirementChildSelectValue(
                          req.description,
                          childRow.name,
                          e.target.value
                        );
                        handleChange(e);
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&:hover fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                        },
                        backgroundColor: "#fff",
                        width: "200px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                      placeholder={
                        childRow.placeholder !== null &&
                        childRow.placeholder !== "" &&
                        childRow.placeholder !== undefined
                          ? childRow.placeholder
                          : "Please specify"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {childRow.adornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                    </>
                  )
                    :childRow?.type === "textfield" ? (<>
                    <Typography
                    color={isEmpty ? 'red' : 'textPrimary'}
                    marginLeft={1}
                    sx={{float:"right", fontSize:"13px",position:"absolute",top:"-50px"}}
                  >
                    {isEmpty ? <span className="error-highlight">Please fill out the missing fields.</span> : ' '}
                  </Typography>
                    <TextField
                     //Vertical Dimension for Occlusion
                      autoFocus
                      type={childRow?.isNumber ? "number" : ""}
                      key={childIndex}
                      size={"medium"}
                      value={req[childRow.name]}
                      onBlur={handleBlur}
                      isEmpty={isEmpty}
                      onChange={(e) => {
                        handleRequirementChildSelectValue(
                          req.description,
                          childRow.name,
                          e.target.value
                        );
                        handleChange(e);
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&:hover fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                        },
                        backgroundColor: "#fff",
                        width: "250px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                      placeholder={
                        childRow.placeholder !== null &&
                        childRow.placeholder !== "" &&
                        childRow.placeholder !== undefined
                          ? childRow.placeholder 
                          : "Please specify"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {childRow.adornment}
                          </InputAdornment>
                        ),
                      }}
                    /> </>) : childRow?.type === "select" ? (<>
                     <Typography
                    color={isEmpty ? 'red' : 'textPrimary'}
                    marginLeft={1}
                    sx={{float:"right", fontSize:"13px",position:"absolute",top:"-50px"}}
                  >
                    {isEmpty ? <span className="error-highlight">Please fill out the missing fields.</span> : ' '}
                  </Typography>
                    <TextField
                      autoFocus
                      label={childRow.placeholder}
                      key={childIndex}
                      size={"medium"}
                      select
                      value={req[childRow.name]}
                      variant="outlined"
                      onBlur={handleBlur}
                      isEmpty={isEmpty}
                      onChange={(e) => {
                        handleRequirementChildSelectValue(
                          req.description,
                          childRow.name,
                          e.target.value
                        )
                        handleChange(e);
                        }
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&:hover fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: isEmpty ? 'red' : '#48add9',
                          },
                        },
                        backgroundColor: "#fff",
                        width: "250px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                    >
                      {childRow?.childItems.map((cItem, cIndex) => (
                        <MenuItem key={cIndex} value={cItem}>
                        {cItem}
                        </MenuItem>
                      ))}
                    </TextField>
                        </>) : childRow?.type === "trueFalse" && (childRow?.name === "nest" || childRow?.name ==="thickness") ? (
                    <Box
                      key={childIndex}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                        
                      }}
                    >
                      <Box sx={{ marginTop: "-35px", maxWidth:"260px"}}>
                      
                        {childRow.placeholder}
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "100px",
                            cursor: "pointer",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "15px",
                            height: "55px",
                            background:
                              req[childRow.name] === true
                                ? brandBlue
                                : "#FBFBFB",
                            color:
                              req[childRow.name] === true ? "#fff" : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementChildSelectValue(
                              req.description,
                              childRow.name,
                              true
                            )
                          }
                        >
                          Yes
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "55px",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "60px",
                            cursor: "pointer",
                            background:
                              req[childRow.name] !== null &&
                              req[childRow.name] !== true
                                ? brandBlue
                                : "#FBFBFB",
                            color:
                              req[childRow.name] !== null &&
                              req[childRow.name] !== true
                                ? "#fff"
                                : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementChildSelectValue(
                              req.description,
                              childRow.name,
                              false
                            )
                          }
                        >
                          No
                        </Box>
                      </Box>
                    </Box>//Gingval Contouring
                  ): childRow?.type === "trueFalse" ? (
                    <Box
                      key={childIndex}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                    >
                      <Box sx={{ marginBottom: "10px" }}>
                        {childRow.placeholder}
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "100px",
                            cursor: "pointer",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "15px",
                            height: "55px",
                            background:
                              req[childRow.name] === true
                                ? brandBlue
                                : "#FBFBFB",
                            color:
                              req[childRow.name] === true ? "#fff" : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementChildSelectValue(
                              req.description,
                              childRow.name,
                              true
                            )
                          }
                        >
                          Yes
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "55px",
                            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                            marginRight: "60px",
                            cursor: "pointer",
                            background:
                              req[childRow.name] !== null &&
                              req[childRow.name] !== true
                                ? brandBlue
                                : "#FBFBFB",
                            color:
                              req[childRow.name] !== null &&
                              req[childRow.name] !== true
                                ? "#fff"
                                : "#000",
                            borderRadius: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleRequirementChildSelectValue(
                              req.description,
                              childRow.name,
                              false
                            )
                          }
                        >
                          No
                        </Box>
                      </Box>
                    </Box>
                  ) : childRow?.type === "buttonGroup" ? (
                    childRow?.childItems.map((cItem, cIndex) => (
                      <Box
                        key={cIndex}
                        sx={{
                          width: "230px",
                          cursor: "pointer",
                          boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
                          marginRight: "15px",
                          height: "55px",
                          background:
                            req[childRow.name] === cItem
                              ? brandBlue
                              : "#FBFBFB",
                          color: req[childRow.name] === cItem ? "#fff" : "#000",
                          borderRadius: "18px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginX: "10px",
                        }}
                        onClick={() => {
                          handleRequirementChildSelectValue(
                            req.description,
                            childRow.name,
                            cItem
                          );
                        }}
                      >
                        {cItem}
                      </Box>
                    ))
                  ) : childRow?.type === "checkbox" ? (
                    <Box
                      key={childIndex}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                    >
                      <Box sx={{ marginRight: "15px" }}>
                        <Checkbox
                          checked={req[childRow.name]}
                          onChange={(e) => {
                            handleRequirementChildSelectValue(
                              req.description,
                              childRow.name,
                              e.target.checked
                            );
                          }}
                        />
                      </Box>
                      <Box>{childRow.placeholder}</Box>
                    </Box>
                  ) : childRow?.type === "label" ? (
                    <Box
                      key={childIndex}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                    >
                      <Box>{childRow.placeholder}</Box>
                    </Box>
                  ) : childRow?.type === "caseNumber" ? (
                    <TextField
                      autoFocus
                      label={childRow.placeholder}
                      key={childIndex}
                      size={"medium"}
                      select
                      value={req[childRow.name]}
                      variant="outlined"
                      onChange={(e) =>
                        handleRequirementChildSelectValue(
                          req.description,
                          childRow.name,
                          e.target.value
                        )
                      }
                      sx={{
                        backgroundColor: "#fff",
                        width: "250px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginX: "10px",
                      }}
                    >
                      {caseNumbers.map((cItem, cIndex) => (
                        <MenuItem key={cIndex} value={cItem}>
                          {cItem}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    ""
                  )
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
           
          </Box>
            
         
      ))}
      
      </Box>
    
       
   
  );
}

export default ListWithChildComponent;
