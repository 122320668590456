import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";

import ToothChartService from "../../../services/toothChart.service";

import CreateCaseConfirmationModal from "../confirmationModal/confirmationModal";
import CaseCreatedModal from "../caseCreatedModal/caseCreatedModal";
import IsLoadingModal from "../isLoadingModal/isLoadingModal";
import DiagnosticWaxUp from "../../../images/diagnostic-wax-up.svg";
import AppendedDx from "../../../images/appended-dx.png";
import AppendedAlternate from "../../../images/appended-alternate.png";

import Form from "../../../images/form.png";
import Asiga from "../../../images/asiga3dfile.png";
import Ackureta from "../../../images/i3dp.png";
import Other from "../../../images/other.png";
import Phrozen from "../../../images/p3d.png";
import Sprintray from "../../../images/sprintray.png";
import Uniz from "../../../images/uniz.png";

import AdminHubService from "../../../services/adminHub.service";
import AdminService from "../../../services/admin.service";
import CaseService from "../../../services/case.service";
import PatientService from "../../../services/patient.service";
import { ErrorSharp } from "@mui/icons-material";
import { groupTeeth } from "../../../helpers/groupTeeth";
import StyledButton from "../../common/button/largeButton";
import IframeDialog from "../../common/iframeDialog";
import { Stack, Typography } from "@mui/material";
import {
  apiUrl,
  brandBlack,
  brandBlue,
  brandColor,
  brandGray,
  brandLight,
  brandOrange,
  brandRed,
  setNamesForUpload,
} from "../../../constants";
import { fileSetUpload } from "../../../helpers/fileSetUpload";
import FileUploaderService from "../../../services/fileUploader.service";
import UpdatingCardModal from "../updatingCardModal/updatingCardModal";
import LoadingModal from "../../common/modal/loadingModal";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { sha256 } from "js-sha256";
import BillingOptionModal from "../../../components/account/billingOptionModal";
import NoAccessPrepayModal from "../../../components/account/noAccessModal";

import useSegment from "../../../hooks/useSegment";
import { fileNestingProduct, prebookingProduct } from "../../../constants/specialProducts";
import AdditionalProducts from "../../common/additionalProducts/additionalProducts";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const _apiUrl = process.env.REACT_APP_API_END_POINT.split("=")[1];

const domain = "EvidentDesign";

const kois_domain = "KOIS";
const design_domain = "EvidentDesign";

function mapStateToProps(state) {
  return { userInfo: state.userInfo };
}

const getPhoneByEmail = async (email) => {
  const response = await fetch(
    _apiUrl + "dentist/getdentistdefaultphonenumber?email=" + email
  );
  return await response.text();
};

function PaymentDetails({
  userInfo,
  selectedTreatment,
  patientDetails,
  handleNextStep,
  treatmentsMaster,
  componentsVisible,
  additionalOrderDetails,
  buttonCancel,
  productsToShow,
  onAddAdditional,
  onClickAboutTreatment
}) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
 
  const [paymentError, setPaymentError] = React.useState("");
  const [treatments, setTreatments] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [hasPaymentDetails, setHasPaymentDetails] = React.useState(false);
  const [caseConfirmationResult, setCaseConfirmationResult] =
    React.useState(null);
  const [pId, setPId] = React.useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [caseCreatedModalOpen, setCaseCreatedModalOpen] = React.useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = React.useState(false);

  const [cardNumber, setCardNumber] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [expiryMonth, setExpiryMonth] = React.useState("");
  const [expiryYear, setExpiryYear] = React.useState("");
  const [ccv, setCcv] = React.useState("");
  const [contactDetails, setContactDetails] = React.useState("");
  const _redirectWebUrl = process.env.REACT_APP_WEB_REDIRECT.split("=")[1];
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmittingCard, setSubmittingCard] = React.useState(false);
  const [treatmentAndUnits, setTreatmentAndUnits] = React.useState([]);
  const [dentistByPractice, setDentistByPractice] = React.useState("");
  const [formattedTreatments, setFormattedTreatments] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoadingUpsell, setIsLoadingUpsellProducts] = React.useState(true);
  const [upsellProducts, setUpsellProducts] = React.useState([]);

  const currentPatient = useSelector(state=>state.patient);
  if(!patientDetails){
    patientDetails = currentPatient;
  }
  const isRush = patientDetails ? patientDetails.IsRush : currentPatient ? currentPatient.isRush : false;
  const isPrebook = patientDetails ? patientDetails.IsPrebook : currentPatient ? currentPatient.isPrebook : false;
  const isUsingGraphyWorkflow = patientDetails ? patientDetails.isUsingGraphyWorkflow : currentPatient ? currentPatient.isUsingGraphyWorkflow : false;
  const navigate = useNavigate();
  const [currentSubDomain, setCurrentSubDomain] = React.useState(function () {
    let current_url = window.location.href.split("//");
    let domain = current_url[1].split(".");
    let subdomain = domain[0];
    return subdomain;
  });
  const [openBillingModal, setOpenBillingModal] = React.useState(false);
  const [userBillingOption, setUserBillingOption] = React.useState("");
  const [openNoAccessModal, setOpenNoAccessModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDentistRole, setIsDentistRole] = React.useState(false);
  const [checkStatusResult, setCheckStatusResult] = React.useState("");
  const segment = useSegment();

  const getLocalBillingOption = async () => {
    setUserBillingOption(localStorage.getItem("locBillingOption"));
  };
  function handleBillingOptionClose() {
    setOpenBillingModal(false);
    setConfirmationModalOpen(true);
  }

  function handleSelectBillingOption(selectedBillOption) {
    console.log(
      "billingoption1 handleSelectBillingOption ",
      selectedBillOption
    );
    setUserBillingOption(selectedBillOption);
    localStorage.setItem("locBillingOption", selectedBillOption);
  }

  function handleOpenBillingOptionModal() {
    const locBillingOption = localStorage.getItem("locBillingOption");
    const enabledPrepayFeature =
      process.env.REACT_APP_EVIDENT_ENABLE_PREPAYDENTIST;
    //For billing option validation
    let isDoctor = false;
    if (userInfo != null && userInfo.Roles != null) {
      isDoctor = userInfo.Roles.includes("Dentist");
    }
    setIsDentistRole(isDoctor);

    if (enabledPrepayFeature && isDoctor) {
      setUserBillingOption(locBillingOption);

      if (
        userInfo.Id != null &&
        (locBillingOption == null ||
          locBillingOption == "null" ||
          locBillingOption == "")
      ) {
        setOpenBillingModal(true);
      } // dentist has billing option proceed to order
      else {
        //if Dentist is Prepay, Check payment status

        if (locBillingOption == "Prepay") {
          AdminService.checkPrepayStatus(userInfo.Id)
            .then((response) => response.json())
            .then((json) => {
              if (json.error === null) {
                if (json.data !== null) {
                  setCheckStatusResult(json.data.access);
                  if (json.data.access != "allowed") {
                    // do not allow to submit case
                    setConfirmationModalOpen(false);
                    setErrorMessage(json.data.message);
                    setOpenNoAccessModal(true);
                  } // allow to process
                  else {
                    setConfirmationModalOpen(true);
                  }
                } // allow to process
                else {
                  setConfirmationModalOpen(true);
                }
              }
            });
        } // STANDARD -- allow to process
        else {
          setConfirmationModalOpen(true);
        }
      }
    } else {
      //If prepay and not dentists block over due payment
      console.log(
        dentistByPractice.billingOption,
        "  block over due payment dentistByPractice if prepay",
        dentistByPractice.id
      );
      if (dentistByPractice.billingOption == "Prepay") {
        console.log(
          "not dentist block over due payment dentistByPractice",
          dentistByPractice.id
        );

        AdminService.checkPrepayStatus(dentistByPractice.id)
          .then((response) => response.json())
          .then((json) => {
            if (json.error === null) {
              setCheckStatusResult(json.data.access);
              if (json.data !== null) {
                if (json.data.access != "allowed") {
                  // do not allow to submit case
                  setConfirmationModalOpen(false);
                  setErrorMessage(json.data.message);
                  setOpenNoAccessModal(true);
                } // allow to process
                else {
                  setConfirmationModalOpen(true);
                }
              } // allow to process
              else {
                setConfirmationModalOpen(true);
              }
            }
          });
        //setConfirmationModalOpen(true);
      } // Standard- allow to process
      else {
        setConfirmationModalOpen(true);
      }
    }
  }

  const handleCancelClick = () => {
     buttonCancel();
  };

  useEffect(() => {
    const previous = treatments;
    const newT = [...previous];
    const imageByDesc = {
      "Appended Model on DX Design": AppendedDx,
      "Appended Model with Alternating Tooth": AppendedAlternate,
    };

    const imageById = {
      1049: Form,
      1097: Ackureta,
      1220: Phrozen,
      1222: Asiga,
      1238: Sprintray,
      1237: Uniz,
    };

    Object.keys(imageByDesc).forEach((description) => {
      const index = previous.findIndex(
        (treatment) => treatment.description === description
      );
      console.log(newT, index);
      if (index >= 0) {
        newT[index].imageLink = imageByDesc[description];
      }
    });

    Object.keys(imageById).forEach((id) => {
      const index = previous.findIndex((treatment) => {
        return treatment.productId === +id;
      });
      console.log(newT, index);
      if (index >= 0 && newT[index].imageLink === null) {
        newT[index].imageLink = imageById[id];
      }
    });

    setFormattedTreatments(newT);
  }, [treatments]);

  const [isOpenPricelistDialog, setIsOpenPricelistDialog] =
    React.useState(false);

  React.useEffect(() => {
    if (
      userInfo.Roles.find((p) => p === "Office Manager" || p === "Assistant")
    ) {
      AdminService.getDentistsByPracticeid(patientDetails?.PracticeId)
        .then((response) => response.json())
        .then((json) => {
          if (patientDetails.PersonnelId && patientDetails.PersonnelId !== "") {
            const personnel = json.find(
              (j) => j.id === patientDetails.PersonnelId
            );
            setPId(personnel.id);
            setDentistByPractice(personnel);
            setupValues(personnel.id, personnel.email, personnel.name);
          }
        });
    } else {
      setPId(userInfo.Id);
      setDentistByPractice(userInfo);
      setupValues(userInfo.Id, userInfo.Email, userInfo.Name);
    }
  }, [selectedTreatment]);

  console.log("TREATMENTS MASTER", treatmentsMaster);
  const setupValues = async (personnelId, email, name) => {
    setIsLoading(true);
    let model = {
      domain: design_domain,
      id: personnelId,
      email: email,
      name: name,
    };
    try {
      const paymentResponse = await AdminService.getPaymentDetails(model);
      const paymentJson = await paymentResponse.json();

      if (
        paymentJson.error === null &&
        paymentJson.data &&
        paymentJson.data.length > 0
      ) {
        let result = JSON.parse(paymentJson.data[0].attributes);
        const d = new Date();
        const year = d.getFullYear();
        const cardExpired =
          Number.parseInt(result.ExpirationYear) &&
          year > Number.parseInt(result.ExpirationYear);

        if (cardExpired) {
          setPaymentError(
            "We noticed that your credit card in your profile has expired,\nkindly update below.\n\nThank you."
          );
        } else {
          const inactiveResponse = await AdminService.checkIfInactive(
            personnelId
          );
          const inactiveJson = await inactiveResponse?.json();

          const isInactive = Boolean(inactiveJson ?? false);

          if (isInactive && !inactiveJson?.error) {
            const chargeResponse = await AdminService.authorizationCharge(
              model
            );
            const chargeJson = await chargeResponse.json();

            if (!chargeJson.error) {
              //todo: if successful charge
              setHasPaymentDetails(true);
            } else {
              if (chargeJson.error.code == 3012) {
                setPaymentError(
                  "We're glad to have you back in evident.\n\nWe noticed that your credit card in your profile has expired, kindly update below.\n\nThank you."
                );
              } else {
                setPaymentError(
                  "We're glad to have you back in evident.\n\nWe noticed that your credit card in your profile has expired, kindly update below.\n\nThank you."
                );
              }
            }
          } else {
            setHasPaymentDetails(true);
          }
        }
      }
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }

    let treatmentList = Object.assign([], selectedTreatment);

    //check if gingival reduction guide is needed
    selectedTreatment.forEach((treat) => {
      if(treat && treat.id !== 'f5b1e6ad-52c0-444b-9ea5-8228d778c8eb'){
        let getGingivalRequirement = treat.requirements.find(
          (x) => x.description === "Gingival Contouring"
        );

        if (typeof getGingivalRequirement !== "undefined") {
          if (getGingivalRequirement.value === "Yes") {
            if (
              getGingivalRequirement.needGingivalGuide !== null &&
              getGingivalRequirement.needGingivalGuide === true
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Gingival Reduction Guide"
              );

              if (typeof getTreatment !== "undefined") {
                let newProduct = {
                  id: getTreatment.treatmentTypeId,
                  description: getTreatment.description,
                  productId: getTreatment.productId,
                  imageLink: getTreatment.imageLink,
                  price: 0,
                  toothNumbers: [],
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                };

                treatmentList.push(newProduct);
              }
            }
          }
        }

        let fabricateRequirement = treat.requirements.find(
          (x) => x.description === "Are you fabricating In-house?"
        );

        if (typeof fabricateRequirement !== "undefined") {
          if (
            fabricateRequirement.value === "Yes" &&
            fabricateRequirement.nest === true
          ) {
            let treatmentsProductsList = [];
            treatmentsMaster.forEach((x) => {
              treatmentsProductsList = treatmentsProductsList.concat(
                x.treatmentTypes
              );
            });
            let printerTreatment = undefined;
            if (fabricateRequirement.printer === "Formlabs") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1049
              );
            } else if (fabricateRequirement.printer === "Ackuretta") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1097
              );
            } else if (fabricateRequirement.printer === "Phrozen") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1220
              );
            } else if (fabricateRequirement.printer === "Asiga") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1222
              );
            } else if (fabricateRequirement.printer === "Sprintray") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1238
              );
            } else if (fabricateRequirement.printer === "Uniz NBEE") {
              printerTreatment = treatmentsProductsList.find(
                (x) => x.productId === 1237
              );
            }

            if (typeof printerTreatment !== "undefined") {
              let newProduct = {
                id: printerTreatment.treatmentTypeId,
                description: printerTreatment.description,
                productId: printerTreatment.productId,
                imageLink: printerTreatment.imageLink,
                price: 0,
                toothNumbers: [],
                files: [],
                caseNotes: "",
                additionalNotes: "",
                dueDate: "",
                requirements: [],
              };

              treatmentList.push(newProduct);
            }
          }
        }

        if (treat.description === "Evident Smile Package") {
          let modelFilesValue = treat.requirements.find(
            (z) => z.description === "Model Files ($15 per additional file)"
          );
          console.log(modelFilesValue.value);
          modelFilesValue.value.forEach((val) => {
            if (
              val ===
              "Printable model file with the current scan and the reduced design file to show the amount of reduction"
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Appended Model on DX Design"
              );

              if (typeof getTreatment !== "undefined") {
                let newProduct = {
                  id: getTreatment.treatmentTypeId,
                  description: getTreatment.description,
                  productId: 1243,
                  imageLink: getTreatment.imageLink,
                  price: 0,
                  toothNumbers: [],
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                  version: "",
                };

                treatmentList.push(newProduct);
              }
            }
            if (
              val === "Every other tooth model (for direct composite bonding),"
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Appended Model with Alternating Tooth"
              );
              if (typeof getTreatment !== "undefined") {
                let newProduct = {
                  id: getTreatment.treatmentTypeId,
                  description: getTreatment.description,
                  productId: 1244,
                  imageLink: getTreatment.imageLink,
                  price: 0,
                  toothNumbers: [],
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                  version: "",
                };

                treatmentList.push(newProduct);
              }
            }
          });
        }
        if (treat.description === "Diagnostic Wax-Up") {
          let modelFilesValue = treat.requirements.find(
            (z) => z.description === "Model Files ($15 per additional file)"
          );

          let prepGuideValue = treat.requirements.find(
            (z) =>
              z.description ===
              "Do you want to add a Prep Guide? (Additional $50)"
          );

          if (typeof prepGuideValue !== "undefined") {
            if (prepGuideValue.value === "Yes") {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Prep Guide"
              );

              let newProduct = {
                id: getTreatment.treatmentTypeId,
                description: getTreatment.description,
                productId: getTreatment.productId,
                imageLink: getTreatment.imageLink,
                price: 0,
                toothNumbers: [],
                files: [],
                caseNotes: "",
                additionalNotes: "",
                dueDate: "",
                requirements: [],
                version: "",
              };

              treatmentList.push(newProduct);
            }
          }

          console.log(modelFilesValue.value);
          modelFilesValue.value.forEach((val) => {
            if (
              val ===
              "Printable model file with the current scan and the reduced design file to show the amount of reduction"
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Appended Model on DX Design"
              );

              console.log(getTreatment, treatmentsProductsList);
              if (typeof getTreatment !== "undefined") {
                let newProduct = {
                  id: getTreatment.treatmentTypeId,
                  description: getTreatment.description,
                  productId: 1243,
                  imageLink: getTreatment.imageLink,
                  price: 0,
                  toothNumbers: [],
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                  version: "",
                };

                treatmentList.push(newProduct);
              }
            }
            if (
              val === "Every other tooth model (for direct composite bonding),"
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Appended Model with Alternating Tooth"
              );
              console.log(getTreatment);
              if (typeof getTreatment !== "undefined") {
                let newProduct = {
                  id: getTreatment.treatmentTypeId,
                  description: getTreatment.description,
                  productId: 1244,
                  imageLink: getTreatment.imageLink,
                  price: 0,
                  toothNumbers: [],
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                  version: "",
                };

                treatmentList.push(newProduct);
              }
            }
          });
        }
        // CHECK IF SURGICAL GUIDE AND PLANNING THEN CHECK IF TOOTH
        if (treat.description === "Surgical Planning and Guide") {
          let reqFullArch = treat.requirements.find(
            (z) => z.description === "Is this a full arch design?"
          );

          let reqStackableGuide = treat.requirements.find(
            (z) => z.description === "Do you want to add a Stackable Guide?"
          );

          let reqRestorativeDesign = treat.requirements.find(
            (z) => z.description === "Does this include a restorative design?"
          );

          if (
            typeof reqStackableGuide !== "undefined" &&
            typeof reqRestorativeDesign !== "undefined"
          ) {
            if (reqStackableGuide.value === "Yes") {
              if (reqRestorativeDesign.value === "Yes") {
                let treatmentsProductsList = [];

                treatmentsMaster.forEach((x) => {
                  treatmentsProductsList = treatmentsProductsList.concat(
                    x.treatmentTypes
                  );
                });
                let getTreatment = treatmentsProductsList.find(
                  (x) =>
                    x.description === "Stackable Guide with Restorative Design"
                );

                console.log(getTreatment, treatmentsProductsList);
                if (typeof getTreatment !== "undefined") {
                  let newProduct = {
                    id: getTreatment.treatmentTypeId,
                    description: getTreatment.description,
                    productId: 1248,
                    imageLink: getTreatment.imageLink,
                    price: 0,
                    toothNumbers: [],
                    files: [],
                    caseNotes: "",
                    additionalNotes: "",
                    dueDate: "",
                    requirements: [],
                    version: "",
                  };

                  treatmentList.push(newProduct);
                }
              } else if (reqRestorativeDesign.value === "No") {
                let treatmentsProductsList = [];

                treatmentsMaster.forEach((x) => {
                  treatmentsProductsList = treatmentsProductsList.concat(
                    x.treatmentTypes
                  );
                });
                let getTreatment = treatmentsProductsList.find(
                  (x) =>
                    x.description === "Stackable Guide with no Restorative Design"
                );

                console.log(getTreatment, treatmentsProductsList);
                if (typeof getTreatment !== "undefined") {
                  let newProduct = {
                    id: getTreatment.treatmentTypeId,
                    description: getTreatment.description,
                    productId: 1247,
                    imageLink: getTreatment.imageLink,
                    price: 0,
                    toothNumbers: [],
                    files: [],
                    caseNotes: "",
                    additionalNotes: "",
                    dueDate: "",
                    requirements: [],
                    version: "",
                  };

                  treatmentList.push(newProduct);
                }
              }
            }
          }

          if (typeof reqFullArch !== "undefined") {
            if (reqFullArch.value === "No") {
              if (treat.toothNumbers.length > 3) {
                let additionalToothCount = treat.toothNumbers.length - 3;

                let additionalTooth = [];

                treat.toothNumbers.forEach((tn, tnIdx) => {
                  if (tnIdx > 2) {
                    additionalTooth.push(tn);
                  }
                });

                let newProduct = {
                  description:
                    "Surgical Planning and Guide - individual teeth (Additional tooth)",
                  consumerApp: "EvidentDesign",
                  customerId: personnelId,
                  productId: 1233,
                  price: 0,
                  units: additionalToothCount,
                  toothNumbers: additionalTooth,
                  files: [],
                  caseNotes: "",
                  additionalNotes: "",
                  dueDate: "",
                  requirements: [],
                  version: "V3",
                  imageLink: treat.imageLink,
                };

                treatmentList.push(newProduct);
              }
            }
          }
        } else if (treat.description === "Photographic Case Presentation") {
          let reqtValue = treat.requirements.find(
            (z) => z.description === "Which Presentation do you want?"
          );

          console.log(treat.requirements, reqtValue.length);
          if (reqtValue.value.length > 1) {
            let treatmentsProductsList = [];

            treatmentsMaster.forEach((x) => {
              treatmentsProductsList = treatmentsProductsList.concat(
                x.treatmentTypes
              );
            });
            let getTreatment = treatmentsProductsList.find(
              (x) => x.description === "Dentofacial Analysis (DFA)"
            );

            console.log(getTreatment, treatmentsProductsList);
            if (typeof getTreatment !== "undefined") {
              let newProduct = {
                id: getTreatment.treatmentTypeId,
                description: getTreatment.description,
                productId: getTreatment.productId,
                imageLink: getTreatment.imageLink,
                price: 0,
                toothNumbers: [],
                files: [],
                caseNotes: "",
                additionalNotes: "",
                dueDate: "",
                requirements: [],
                version: "",
              };
              console.log(newProduct);
              treatmentList.push(newProduct);
            }
          }
        }
      } else {
      }
    });

    let modelToGetPrice = {
      consumerApp: domain,
      customerId: personnelId,
      productIds: [],
    };

    treatmentList.forEach((treat) => {
      if (treat.description === "Diagnostic Wax-Up") {
        let reqtValue = treat.requirements.find(
          (z) =>
            z.description ===
            "Do you have an existing Smile Mock-up or Dentofacial Analysis case to follow?"
        );
        if (typeof reqtValue !== "undefined") {
          modelToGetPrice.productIds.push(1002);
          treat.productId = 1002;
          console.log(additionalOrderDetails);
          if (
            (reqtValue.value === "Yes" &&
              reqtValue.caseNumber !== "" &&
              reqtValue.caseNumber !== null) ||
            (additionalOrderDetails !== null &&
              additionalOrderDetails.length !== 0)
          ) {
            modelToGetPrice.productIds.push(1249);
            treat.productId = 1249;
          }
        }
      } else if (treat.description === "Crown & Bridge") {
        modelToGetPrice.productIds.push(1009);
        treat.productId = 1009;
      } else if (treat.description === "Models") {
        let reqM = treat.requirements.find(
          (z) => z.description === "Type of Model"
        );

        let reqA = treat.requirements.find(
          (z) => z.description === "Alignment On Semi-Adjustable Articulator"
        );

        if (typeof reqM !== "undefined") {
          if (reqM.value === "Base Model") {
            if (typeof reqA !== "undefined") {
              if (reqA.value === "Yes") {
                modelToGetPrice.productIds.push(1014);
                treat.productId = 1014;
              } else {
                modelToGetPrice.productIds.push(1040);
                treat.productId = 1040;
              }
            } else {
              modelToGetPrice.productIds.push(1040);
              treat.productId = 1040;
            }
          } else if (reqM.value === "Implant Model") {
            modelToGetPrice.productIds.push(1051);

            treat.productId = 1051;
          } else if (reqM.value === "Ortho Model") {
            modelToGetPrice.productIds.push(1053);

            treat.productId = 1053;
          }
        }
      } else if (treat.description === "Aligners") {
        let reqA = isUsingGraphyWorkflow;
        if (treat.serviceProviderSelected === "Fabricate") {
          modelToGetPrice.productIds.push(1017);
          treat.productId = 1017;
        } else if (treat.serviceProviderSelected === "Design") {
          console.log("currentSubdomain" + currentSubDomain);
          if (reqA != null) {
            if (reqA === true) {
              if (
                currentSubDomain === "graphy" ||
                currentSubDomain === "preprodgraphy"
              ) {
                modelToGetPrice.productIds.push(1263);
                treat.productId = 1263;
              } else {
                modelToGetPrice.productIds.push(1257);
                treat.productId = 1257;
              }
            } else if (reqA === false) {
              modelToGetPrice.productIds.push(1018);
              treat.productId = 1018;
            }
          } else {
            modelToGetPrice.productIds.push(1215);
            treat.productId = 1215;
          }
        }
      } else if (treat.description === "Surgical Planning and Guide") {
        let reqFullArch = treat.requirements.find(
          (z) => z.description === "Is this a full arch design?"
        );

        let reqArchDesign = treat.requirements.find(
          (z) => z.description === "Arch Design"
        );

        let reqRestorativeDesign = treat.requirements.find(
          (z) => z.description === "Does this include a restorative design?"
        );

        // let reqStackableGuide = treat.requirements.find(
        //   (z) => z.description === "Do you want to add a Stackable Guide?"
        // );

        if (
          typeof reqFullArch !== "undefined" &&
          typeof reqArchDesign !== "undefined" &&
          typeof reqRestorativeDesign !== "undefined"
          // && typeof reqStackableGuide !== "undefined"
        ) {
          if (reqFullArch.value === "Yes") {
            if (reqRestorativeDesign.value === "Yes") {
              if (reqArchDesign.value === "Both Arches") {
                modelToGetPrice.productIds.push(1232);
                treat.productId = 1232;
                console.log(1232);
              } else if (
                reqArchDesign.value === "Upper Arch Only" ||
                reqArchDesign.value === "Lower Arch Only"
              ) {
                modelToGetPrice.productIds.push(1230);
                treat.productId = 1230;
                console.log(1230);
              }
            } else if (reqRestorativeDesign.value === "No") {
              if (reqArchDesign.value === "Both Arches") {
                modelToGetPrice.productIds.push(1231);
                treat.productId = 1231;
                console.log(1231);
              } else if (
                reqArchDesign.value === "Upper Arch Only" ||
                reqArchDesign.value === "Lower Arch Only"
              ) {
                modelToGetPrice.productIds.push(1229);
                treat.productId = 1229;
                console.log(1229);
              }
            }
          } else if (reqFullArch.value === "No") {
            modelToGetPrice.productIds.push(1228);

            // if (treat.toothNumbers.length > 3) {
            //   modelToGetPrice.productIds.push(1233);
            // }

            treat.productId = 1228;
          }

          // if (reqStackableGuide.value === "Yes") {
          if (reqRestorativeDesign.value === "Yes") {
            modelToGetPrice.productIds.push(1248);
          } else if (reqRestorativeDesign.value === "No") {
            modelToGetPrice.productIds.push(1247);
          }
          // }
        }
      } else if (treat.description === "All on X Implants") {
        let reqF = treat.requirements.find(
          (z) => z.description === "Treatment Requirement"
        );
        console.log(reqF);
        if (typeof reqF !== "undefined") {
          if (reqF.value === "Both Temp and Final") {
            modelToGetPrice.productIds.push(1214);
            treat.productId = 1214;
          } else if (reqF.value === "PMMA (Temporary) Only") {
            modelToGetPrice.productIds.push(1044);
            treat.productId = 1044;
          } else if (reqF.value === "Final Restoration (Zirconia) Only") {
            modelToGetPrice.productIds.push(1044);
            treat.productId = 1044;
          }
          if (Boolean(additionalOrderDetails?.notes)) {
            modelToGetPrice.productIds.push(1219);
            treat.productId = 1219;
          }
        }
      } else if (treat.description === "Photographic Case Presentation") {
        let reqtValue = treat.requirements.find(
          (z) => z.description === "Which Presentation do you want?"
        );
        console.log(reqtValue, reqtValue.value.length);
        if (reqtValue.value.length > 1) {
          modelToGetPrice.productIds.push(1028);
          treat.productId = 1028;
        } else if (reqtValue.value.length === 1) {
          reqtValue.value.forEach((val) => {
            if (val === "Dentofacial Analysis (DFA)") {
              modelToGetPrice.productIds.push(1027);
              treat.productId = 1027;
            }
            if (val === "Virtual Smile Mock up with Outline") {
              modelToGetPrice.productIds.push(1028);
              treat.productId = 1028;
            }
          });
        }
      } else if (treat.description === "Implant Crowns") {
        let castValue = treat.requirements.find(
          (z) => z.description === "Type of Implant Restoration"
        );
        /* let tiValue = treat.requirements.find(
          (z) => z.description === "Will this be on Ti-Base?"
        ); */

        if (castValue.value === "Screw-Retained Crown on Ti-Base") {
          modelToGetPrice.productIds.push(1058);
          treat.productId = 1058;
          treat.description = "Screw-Retained Crown on Ti-Base";
        } else if (
          castValue.value === "Screw-Retained Crown (Direct to Fixture)"
        ) {
          modelToGetPrice.productIds.push(1021);
          treat.productId = 1021;
          treat.description = "Screw-Retained Crown (Direct to Fixture)";
        } else if (
          castValue.value === "Cement Retained Crown w/ Custom Abutment"
        ) {
          modelToGetPrice.productIds.push(1060);
          treat.productId = 1060;
          treat.description = "Cement Retained Crown w/ Custom Abutment";
        } else if (
          castValue.value === "Screw-Retained Crown w/ Custom Abutment"
        ) {
          modelToGetPrice.productIds.push(1267);
          treat.productId = 1267;
          treat.description = "Screw-Retained Crown w/ Custom Abutment";
        } else if (castValue.value === "Custom Abutment (Abutment Only)") {
          modelToGetPrice.productIds.push(1019);
          treat.productId = 1019;
          treat.description = "Custom Abutment (Abutment Only)";
        } else if (castValue.value === "Crown with Access Hole (Crown Only)") {
          modelToGetPrice.productIds.push(1022);
          treat.productId = 1022;
          treat.description = "Crown with Access Hole (Crown Only)";
        }
      } else if (treat.description === "Clear Retainers") {
        let graphyValue = isUsingGraphyWorkflow;

        let reqtValue = treat.requirements.find(
          (z) => z.description === "How many retainers do you need?"
        );
        let archCountValue = treat.requirements.find(
          (z) => z.description === "This Clear Retainer is for?"
        );

        let stlValue = treat.requirements.find(
          (z) => z.description === "Are you fabricating In-house?"
        );

        let removedBracketsValue = treat.requirements.find(
          (z) =>
            z.description ===
            "Do you need us to digitally remove the brackets? (Additional $25)"
        );

        if (removedBracketsValue.value === "Yes") {
          modelToGetPrice.productIds.push(1254);
          // treat.productIds.push(1254);

          let treatmentsProductsList = [];

          treatmentsMaster.forEach((x) => {
            treatmentsProductsList = treatmentsProductsList.concat(
              x.treatmentTypes
            );
          });
          let getTreatment = treatmentsProductsList.find(
            (x) => x.description === "Digitally Removed Brackets"
          );

          console.log(getTreatment, treatmentsProductsList);
          if (typeof getTreatment !== "undefined") {
            let newProduct = {
              id: getTreatment.treatmentTypeId,
              description: getTreatment.description,
              productId: 1254,
              imageLink: getTreatment.imageLink,
              price: 0,
              toothNumbers: [],
              files: [],
              caseNotes: "",
              additionalNotes: "",
              dueDate: "",
              requirements: [],
              version: "",
            };

            treatmentList.push(newProduct);
          }
        }
        if (treat.serviceProviderSelected === "Fabricate") {
          if (reqtValue.value === "Set of 3") {
            modelToGetPrice.productIds.push(1099);
            treat.productId = 1099;
          } else {
            modelToGetPrice.productIds.push(1221);
            treat.productId = 1221;
          }
        } else {
          if (graphyValue === true) {
            if (reqtValue.value == "Set of 3") {
              modelToGetPrice.productIds.push(1251);
              treat.productId = 1251;
            } else {
              modelToGetPrice.productIds.push(1250);
              treat.productId = 1250;
            }
          } else {
            if (stlValue.value === "Yes") {
              if (reqtValue.value === "Set of 3") {
                modelToGetPrice.productIds.push(1252);
                treat.productId = 1252;
              } else {
                modelToGetPrice.productIds.push(1252);
                treat.productId = 1252;
              }
            } else {
              if (reqtValue.value === "Set of 3") {
                modelToGetPrice.productIds.push(1252);
                treat.productId = 1252;
              } else {
                modelToGetPrice.productIds.push(1252);
                treat.productId = 1252;
              }
            }
          }
        }
      } else if (treat.description === "Devices") {
        let reqtValue = treat.requirements.find(
          (z) => z.description === "Which Protocol do you want?"
        );
        let deprogValue = treat.requirements.find(
          (z) => z.description === "Deprogrammer Options"
        );

        let nightguardValue = treat.requirements.find(
          (z) => z.description === "Nightguard Options"
        );

        let mandebularValue = treat.requirements.find(
          (z) => z.description === "Mandibular Anterior Jig Options"
        );

        let orthopedicValue = treat.requirements.find(
          (z) => z.description === "Orthopedic Appliance Options"
        );

        if (reqtValue.value === "Kois Deprogrammer") {
          if (deprogValue.value === "Standard") {
            modelToGetPrice.productIds.push(1029);
            treat.productId = 1029;
          } else if (deprogValue.value === "With Anterior Coverage") {
            modelToGetPrice.productIds.push(1030);
            treat.productId = 1030;
          } else if (deprogValue.value === "With Posterior Coverage") {
            modelToGetPrice.productIds.push(1031);
            treat.productId = 1031;
          }
        } else if (reqtValue.value === "Kois Nightguard") {
          if (nightguardValue.value === "Maxillary with anterior platform") {
            modelToGetPrice.productIds.push(1035);
            treat.productId = 1035;
          } else if (
            nightguardValue.value === "Maxillary without anterior platform"
          ) {
            modelToGetPrice.productIds.push(1034);
            treat.productId = 1034;
          } else if (nightguardValue.value === "Mandibular") {
            modelToGetPrice.productIds.push(1036);
            treat.productId = 1036;
          }
        } else if (reqtValue.value === "Mandibular Anterior Jig") {
          if (mandebularValue.value === "Platform") {
            modelToGetPrice.productIds.push(1038);
            treat.productId = 1038;
          } else if (mandebularValue.value === "Cobal Balancer") {
            modelToGetPrice.productIds.push(1039);
            treat.productId = 1039;
          }
        } else if (reqtValue.value === "Orthopedic Appliance") {
          if (orthopedicValue.value === "Mandibular") {
            modelToGetPrice.productIds.push(1033);
            treat.productId = 1033;
          } else if (orthopedicValue.value === "Maxillary") {
            modelToGetPrice.productIds.push(1032);
            treat.productId = 1032;
          }
        }
      } else if (treat.description === "Casts") {
        let reqtValue = treat.requirements.find(
          (z) => z.description === "Which cast do you want?"
        );
        if (
          reqtValue.value === "Facially Referenced Mounted and Printable Casts"
        ) {
          modelToGetPrice.productIds.push(1026);
          treat.productId = 1026;
        } else if (reqtValue.value === "Stone Mountable Printable Casts") {
          modelToGetPrice.productIds.push(1040);
          treat.productId = 1040;
        } else if (reqtValue.value === "Hinge Mounted Casts") {
          modelToGetPrice.productIds.push(1041);
          treat.productId = 1041;
        }
      } else if (treat.description === "Digital Complete Dentures") {
        let reqtValue = treat.requirements.find(
          (z) => z.description === "Type of Complete Dentures"
        );
        if (reqtValue.value === "Full Dentures") {
          modelToGetPrice.productIds.push(1046);
          treat.productId = 1046;
        } else if (reqtValue.value === "Copy Dentures") {
          modelToGetPrice.productIds.push(1096);
          treat.productId = 1096;
        } else if (reqtValue.value === "Truedent Denture") {
          modelToGetPrice.productIds.push(1268);
          treat.productId = 1268;
        }
      } else if (treat.description === "Digital Partial Dentures") {
        let reqtValue = treat.requirements.find(
          (z) =>
            z.description ===
            "Cast Partial Framework or Acrylic Partial Denture"
        );
        if (reqtValue.value === "Acrylic Partial Denture") {
          modelToGetPrice.productIds.push(1056);
          treat.productId = 1056;
        } else if (reqtValue.value === "Cast Partial Framework") {
          modelToGetPrice.productIds.push(1047);
          treat.productId = 1047;
        }
      } else if (treat.description === "CEREC Crown & Bridge") {
        modelToGetPrice.productIds.push(1285);
        treat.productId = 1285;
      } else {
        console.log(treat.productId);
        modelToGetPrice.productIds.push(treat.productId);
      }
    });

    console.log(
      "treatmentList, modelToGetPrice.productIds",
      treatmentList,
      modelToGetPrice.productIds,
      modelToGetPrice
    );
    const treatmentsWithUnits = [];
    AdminHubService.getProductPricing(modelToGetPrice)
      .then((response) => response.json())
      .then((json) => {
        let totalAmount = 0;
        let data = json.data;
        console.log(data);
        data.forEach((price) => {
          let getProductToUpdate = treatmentList.find(
            (x) => x.productId === price.id
          );

          console.log(
            treatmentList.filter((x) => x.productId === price.id).length
          );

          if (typeof getProductToUpdate !== "undefined") {
            // if (
            //   treatmentList.filter((x) => x.productId === price.id).length > 1
            // ) {
            //   treatmentList
            //     .filter((x) => x.productId === price.id)
            //     .forEach((tl) => {
            //       console.log(tl);
            //       let treatmentAndUnit = {
            //         description: tl.description,
            //         units: 1,
            //       };

            //       let toothNumbersOrUnit =
            //         tl.toothNumbers !== undefined
            //           ? tl.toothNumbers.length
            //           : tl.units;

            //       if (price.measurementType === 0) {
            //         treatmentAndUnit.units = toothNumbersOrUnit;
            //         tl.price = price.pricePerUnit * toothNumbersOrUnit;
            //       } else {
            //         tl.price = price.pricePerUnit;
            //       }

            //       let treatmentAndUnitsCopy = Object.assign(
            //         [],
            //         treatmentAndUnits
            //       );
            //       treatmentAndUnitsCopy.push(treatmentAndUnit);
            //       setTreatmentAndUnits(treatmentAndUnitsCopy);
            //     });
            // } else {
            let treatmentAndUnit = {
              description: getProductToUpdate.description,
              units: 1,
            };

            console.log(
              "getProductToUpdate.toothNumbers",
              getProductToUpdate.toothNumbers
            );
            let toothNumbersOrUnit =
              getProductToUpdate.toothNumbers !== undefined
                ? getProductToUpdate.toothNumbers.length
                : getProductToUpdate.units;

            getProductToUpdate.units = toothNumbersOrUnit;
            let pricePerUnit = price.pricePerUnit;
            // if (isRush) {
            //   const rushPricePerUnit = 5;
            //   if (pricePerUnit === 0) return;
            //   pricePerUnit += rushPricePerUnit;
            // }

            if (price.measurementType === 0) {
              treatmentAndUnit.units = toothNumbersOrUnit;
              console.log(toothNumbersOrUnit, getProductToUpdate);
              getProductToUpdate.price = pricePerUnit * toothNumbersOrUnit;
            } else {
              getProductToUpdate.price = pricePerUnit;
            }

            treatmentsWithUnits.push(treatmentAndUnit);
            // }
          }
        });

        treatmentList.forEach((x) => {
          if (x.description === "Evident Smile Package" && x.toothNumbers.length > 0) {
            var arches = ToothChartService.archesCount(x.toothNumbers.map(tn => tn.tooth));
            totalAmount += Number(x.price * arches);
          } else if (x.description === "All on X Implants") {
            // check if all on x implants have requirement "This All on X is For"
            let reqF = x.requirements.find(r => r.description === "This All on X is For");
            if (reqF && reqF.value === "Both Upper And Lower Arches") {
              totalAmount += Number(x.price * 2)
            } else {
              totalAmount += Number(x.price);
            }
          }
          else {
            totalAmount += Number(x.price);
          }
        });

        if (isPrebook) {
          totalAmount += 99;
        }

        setTreatmentAndUnits(treatmentsWithUnits);
        console.log(treatmentList);
        setTreatments(treatmentList);
        setTotalAmount(totalAmount);
        setIsLoading(false);
      });
  };

  const cancelSubmission = () => {
    segmentTriggerEvent("hub_cancel_submit");
    handleNextStep(-1, selectedTreatment, patientDetails);
    handleCancelClick();
  };

  const handleConfirmClose = (confirm) => {
    setConfirmationModalOpen(false);
    if (confirm) {
      segmentTriggerEvent("hub_successful_order");
      setIsLoadingModalOpen(true);
      if (patientDetails?.Id === "") {
        let addPatientModel = {
          PracticeId: patientDetails?.PracticeId,
          PersonnelId: pId,
          FirstName: patientDetails?.FirstName,
          LastName: patientDetails?.LastName,
          CreatedBy: userInfo?.Id,
          Email: patientDetails?.Email,
          DateOfBirth:
            patientDetails?.DateOfBirth !== ""
              ? new Date(patientDetails?.DateOfBirth)
              : null,
        };

        PatientService.addPatient(addPatientModel)
          .then((response) => response.json())
          .then((json) => {
            const patientId = json.data;

            let filesToUpdate = [];
            let selTreatIdx = 0;
            selectedTreatment.forEach((y) => {
              console.log(y.files);

              // upload to storage
              const uploadResponse = y.files.map(async (j) => {
                const data = await FileUploaderService.uploadFileAsync(
                  j.file,
                  `patient/${patientId}/${j.fileId}`
                );
                console.log(data);
                return j;
              });

              Promise.all(uploadResponse).then((uploadedFiles) => {
                const groupedFiles = {};
                uploadedFiles.forEach((file) => {
                  if (!Boolean(groupedFiles[file.setName])) {
                    groupedFiles[file.setName] = [];
                  }

                  groupedFiles[file.setName] = [
                    ...groupedFiles[file.setName],
                    {
                      PatientId: json.data,
                      FileId: file.fileId,
                      Filename: file.fileName,
                    },
                  ];
                });

                const uploads = Object.keys(groupedFiles).map(
                  async (setName) => {
                    groupedFiles[setName] = groupedFiles[setName].map((file) =>
                      // upload files
                      PatientService.uploadPatientFile(file, setName)
                    );

                    return Promise.all(groupedFiles[setName]).then(
                      async (responses) => {
                        return Promise.all(
                          // get json data
                          responses.map((response) => response.json())
                        ).then((data) => {
                          if (setNamesForUpload.includes(setName)) {
                            // upload set
                            return fileSetUpload(
                              patientId,
                              setName,
                              data.map((f) => f.id)
                            ).then((set) => {
                              const formattedFiles = data.map((f) => ({
                                fileId: f.fileId,
                                patientFileId: set.id,
                                patientFileExtensionId: f.id,
                              }));
                              return formattedFiles;
                            });
                          } else {
                            const formattedFiles = data.map((f) => ({
                              fileId: f.fileId,
                              patientFileId: f.id,
                            }));
                            return formattedFiles;
                          }
                        });
                      }
                    );
                  }
                );

                Promise.all(uploads).then((filesData) => {
                  selTreatIdx++;
                  let filesres = filesData.flat();
                  console.log(filesres);
                  filesres.forEach((x) => {
                    filesToUpdate.push(x);
                  });

                  console.log(filesToUpdate);
                  if (selTreatIdx === selectedTreatment.length) {
                    addCase(json.data, filesToUpdate, "from inside");
                  }
                });
              });
            });
          });
      } else {
        addCase("", [], "from else");
      }
    }
  };

  const hasEmail = async (email) => {};

  const conversionsTriggerPurchaseEvent = async (
    user,
    totalAmount,
    treatmentsSelected
  ) => {
    const contents = [];
    treatmentsSelected.forEach((treatment) => {
      contents.push({
        id: treatment.productId,
        quantity: 1,
        item_price: treatment.price,
      });
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var json = {
      data: [
        {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          action_source: "website",
          user_data: user,
          custom_data: {
            currency: "USD",
            value: totalAmount,
            content_type: "product_group",
            contents: contents,
          },
        },
      ],
    };

    //if REACT_APP_META_CONVERSIONS_TEST_PAYLOAD has value, add test_event_code key to json
    const testCode = process.env.REACT_APP_META_CONVERSIONS_TEST_PAYLOAD;
    if (testCode !== "LIVE") {
      json.test_event_code = testCode;
    }

    var raw = JSON.stringify(json);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_PIXEL_API_URL}${process.env.REACT_APP_DENTIST_HUB_PIXEL_ID}/events?access_token=${process.env.REACT_APP_DENTIST_HUB_PIXEL_ACCESS_TOKEN}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => console.log(data));
  };

  const addCase = async (patientId, filesToUpdate, from) => {
    console.log("create caseeeeeeeeeeeeeeeeeeeeeeeeeeeeee", from);
    let dentistName = "";
    // userinfo uses pascal case, server returns camel case
    if (dentistByPractice.FirstName) {
      dentistName = `${dentistByPractice.FirstName} ${dentistByPractice.LastName}`;
    } else {
      dentistName = `${dentistByPractice.firstName} ${dentistByPractice.lastName}`;
    }

    let model = {
      PersonnelId: pId,
      PatientId: patientId === "" ? patientDetails?.Id : patientId,
      PracticeId: patientDetails?.PracticeId,
      ServiceProviders: [
        {
          LabId: "00000000-0000-0000-0000-000000000000",
          Type: "Design", //selectedTreatment[0].serviceProviderSelected,
        },
      ],
      OutputTypeId: "ec0f1b62-2f11-4f0f-748b-08d6b658bf50",
      LabId: "00000000-0000-0000-0000-000000000000",
      Comment:
        '{"outputType":Design,"products":[{"productName":"Diagnostic Wax-Up","productDetails":[{"reqName":"Tooth Chart","reqValue":"13"},{"reqName":"Type of Restoration (Multiple)","reqValue":"13 (Veneers)"},{"reqName":"Additive wax-up","reqValue":"No"},{"reqName":"Is this a bridge?","reqValue":"No"}]}],"impression":""}',
      OrderSummaryPdfUrl: process.env.REACT_APP_BLOB_URL,
      OrderSummary: {
        dentistName: dentistName,
        orderAdditionalFiles: [],
        orderDate: new Date(),
        patientName: patientDetails ? patientDetails.FirstName + " " + patientDetails.LastName : currentPatient ? `${currentPatient.FirstName} ${currentPatient.LastName}` : null,
        practiceName: patientDetails ? patientDetails.PracticeName : currentPatient ? currentPatient.practiceName : "",
        sendingPhysicalImpression: false,
        orderReturnDate: "",
        orderProducts: [],
        orderSupportingFiles: [],
      },
      ReturnDate: patientDetails.ReturnDate,
      CreatedBy: userInfo?.Id,
      CaseTreatments: [],
      CaseTreatmentModels: [],
      AdditionalDocuments: [],
      CasePatientFiles: [],
      PatientFilesRecords: [],
      IsSameDayDentistry: patientDetails.IsSameDayDentistry,
      IsPrepOrFinalRestoration: patientDetails.IsPrepOrFinalRestoration,
      IsRush: patientDetails.IsRush,
      IsPrebook: patientDetails.IsPrebook,
      PrebookId: patientDetails.PrebookId,
      isUsingGraphyWorkflow: patientDetails.isUsingGraphyWorkflow,
    };

    let commentModel = {
      outputType: "Design", //selectedTreatment[0].serviceProviderSelected,
      products: [],
      dentalNotation: ToothChartService.getDentalNotationSystem(),
    };

    // let treatmentsSelected = Object.assign([], selectedTreatment);
    let treatmentsSelected = Object.assign([], treatments);

    // let getAdditional = treatments.filter(
    //   (x) => x.description !== treatmentsSelected[0].description
    // );

    // if (getAdditional.length > 0) {
    //   treatmentsSelected = treatmentsSelected.concat(getAdditional);
    // }

    console.log('treatmentsSelected',treatmentsSelected);

    if(treatmentsSelected && treatmentsSelected.requiredFiles){
      treatmentsSelected.requiredFiles.forEach((fileSection) => {
          fileSection.files.forEach((z) => {
            if (filesToUpdate.length === 0) {
              let fileModel = {
                PatientFileId: z.patientFileId,
                PatientFileExtensionId:
                  z.patientFileExtensionId ||
                  "00000000-0000-0000-0000-000000000000",
              };

              model.PatientFilesRecords.push(fileModel);
            } else {
              let getFileToUpdate = filesToUpdate.find(
                (y) => y.fileId === z.fileId
              );

              if (typeof getFileToUpdate !== "undefined") {
                let fileModel = {
                  PatientFileId: getFileToUpdate.patientFileId,
                  PatientFileExtensionId:
                    getFileToUpdate.patientFileExtensionId ||
                    "00000000-0000-0000-0000-000000000000",
                };

                model.PatientFilesRecords.push(fileModel);
              }
            }

            model.OrderSummary.orderAdditionalFiles.push({
              fileType: z.fileType,
              fileName: z.fileName,
            });
          });
      });      
    }


    treatmentsSelected.forEach((x) => {
      console.log('treatmentselectedx',x);
      // if (
      //   x.description !==
      //   "Surgical Planning and Guide - individual teeth (Additional tooth)"
      // ) {
      // model.CaseTreatments.push(x.description);
      // }

      let caseTreatmentModel = {
        caseType: x.description,
        caseTypeParent: x.description,
        consumerApp: "EvidentDesign",
        customerId: pId,
        productId: x.description === "Crown & Bridge" ? "1009" : x.productId,
        units: treatmentAndUnits.find(
          (tau) => tau.description === x.description
        )
          ? treatmentAndUnits.find((tau) => tau.description === x.description)
              .units
          : x.description === "Crown & Bridge" ||
            x.description === "Diagnostic Wax-Up" ||
            x.description === "Provisional Restorations"
          ? x.toothNumbers !== undefined
            ? x.toothNumbers.length
            : x.units
          : 1,
        version:
          x.productId === 1013 ||
          x.productId === 1040 ||
          x.productId === 1243 ||
          x.productId === 1244
            ? null
            : "V3",
        multiplier: 1,
      };

      let toothNumbers = [];
      let toothNumberRestorations = [];

      x.toothNumbers.forEach((x) => {
        toothNumbers.push(x.tooth);
        if (caseTreatmentModel.productId === 1285) {
          toothNumberRestorations.push({
            toothNumber: x.tooth,
            restoration: x.restorationType,
            note: x.note,
            device: x.device,
            designMode: x.designMode,
            materialClass: x.materialClass,
            material: x.material,
          });
        } else {
          toothNumberRestorations.push({
            toothNumber: x.tooth,
            restoration: x.restorationType,
            note: x.note,
            widthAndLength: x.widthAndLength,
            gingivalContouring: x.gingivalContouring,
          });
        }
      });

      if (x.description === "Evident Smile Package") {
        var filesValue = x.requirements.find(
          (z) =>
            z.description ===
            "Select up to 4 files to be included in the package"
        );

        caseTreatmentModel.productInPackage = filesValue.valueid;
        caseTreatmentModel.productInPackage.push(1236);

        var arches = ToothChartService.archesCount(toothNumbers);
        caseTreatmentModel.multiplier = arches;
        caseTreatmentModel.units = arches;

        let modelFilesValue = x.requirements.find(
          (z) => z.description === "Model Files ($15 per additional file)"
        );

        modelFilesValue.value.forEach((val) => {
          if (
            val ===
            "Printable model file with the current scan and the reduced design file to show the amount of reduction"
          ) {
            caseTreatmentModel.productInPackage.push(1243);
          }
          if (
            val === "Every other tooth model (for direct composite bonding),"
          ) {
            caseTreatmentModel.productInPackage.push(1244);
          }
        });

        let gingivalRequirement = x.requirements.find(
          (z) => z.description === "Gingival Contouring"
        );

        if (typeof gingivalRequirement !== "undefined") {
          if (gingivalRequirement.value === "Yes") {
            if (
              gingivalRequirement.needGingivalGuide !== null &&
              gingivalRequirement.needGingivalGuide === true
            ) {
              let treatmentsProductsList = [];

              treatmentsMaster.forEach((x) => {
                treatmentsProductsList = treatmentsProductsList.concat(
                  x.treatmentTypes
                );
              });
              let getTreatment = treatmentsProductsList.find(
                (x) => x.description === "Gingival Reduction Guide"
              );

              if (typeof getTreatment !== "undefined") {
                caseTreatmentModel.productInPackage.push(
                  getTreatment.productId
                );
              }
            }
          }
        }
      } else if (x.description === "Crown & Bridge") {
        var req1 = x.requirements.find(
          (z) => z.description === "Restoration Type"
        );

        const hasOnlyInlayOnlay = x.toothNumbers.every(
          (item) => item.restorationType === "Inlay/Onlay"
        );

        if (hasOnlyInlayOnlay) {
          caseTreatmentModel.productId = 1259; //Inlay/Onlay
        } else if (typeof req1 !== "undefined") {
          switch (req1.type) {
            case "EMAX":
              caseTreatmentModel.productId = 1003;
              break;
            case "FCZ":
              caseTreatmentModel.productId = 1005;
              break;
            case "PFZ":
              caseTreatmentModel.productId = 1004;
              break;
            case "FCC-FGC":
              caseTreatmentModel.productId = 1006;
              break;

            case "Trial Smile (One-piece veneer style to try in over the patients existing teeth - consultative purposes)":
              caseTreatmentModel.productId = 1246;
              break;
            case "Virtually prepped shell temps (with a 0.6 mm virtual prep)":
              caseTreatmentModel.productId = 1234;
              break;
            case "Standard temporaries (prepped teeth)":
              caseTreatmentModel.productId = 1245;
              break;
          }
        }
      } else if (x.description === "Surgical Planning and Guide") {
        let reqFullArch = x.requirements.find(
          (z) => z.description === "Is this a full arch design?"
        );

        let reqArchDesign = x.requirements.find(
          (z) => z.description === "Arch Design"
        );

        let reqRestorativeDesign = x.requirements.find(
          (z) => z.description === "Does this include a restorative design?"
        );

        let reqStackableGuide = x.requirements.find(
          (z) => z.description === "Do you want to add a Stackable Guide?"
        );

        if (
          typeof reqFullArch !== "undefined" &&
          typeof reqArchDesign !== "undefined" &&
          typeof reqRestorativeDesign !== "undefined"
          // && typeof reqStackableGuide !== "undefined"
        ) {
          console.log("y0");
          if (reqFullArch.value === "Yes") {
            if (reqRestorativeDesign.value === "Yes") {
              if (reqArchDesign.value === "Both Arches") {
                caseTreatmentModel.productId = 1232;
              } else if (
                reqArchDesign.value === "Upper Arch Only" ||
                reqArchDesign.value === "Lower Arch Only"
              ) {
                caseTreatmentModel.productId = 1230;
              }
            } else if (reqRestorativeDesign.value === "No") {
              if (reqArchDesign.value === "Both Arches") {
                caseTreatmentModel.productId = 1231;
              } else if (
                reqArchDesign.value === "Upper Arch Only" ||
                reqArchDesign.value === "Lower Arch Only"
              ) {
                caseTreatmentModel.productId = 1229;
              }
            }
          } else if (reqFullArch.value === "No") {
            caseTreatmentModel.productId = 1228;
          }
        }
      } else if (x.description === "All on X Implants") {
        let reqF = x.requirements.find(
          (z) => z.description === "Treatment Requirement"
        );
        let archCountValue = x.requirements.find(
          (z) => z.description === "This All on X is For"
        );
        caseTreatmentModel.productInPackage = [];
        if (typeof reqF !== "undefined") {
          if (Boolean(additionalOrderDetails?.notes)) {
            caseTreatmentModel.productId = 1219;
          } else {
            if (reqF.value === "Both Temp and Final") {
              caseTreatmentModel.productId = 1214;
              caseTreatmentModel.productInPackage.push(1044);
              caseTreatmentModel.productInPackage.push(1219);
            } else if (reqF.value === "PMMA (Temporary) Only") {
              caseTreatmentModel.productId = 1044;
            } else if (reqF.value === "Final Restoration (Zirconia) Only") {
              caseTreatmentModel.productId = 1044;
            }
          }
          if (archCountValue.value === "Both Upper And Lower Arches") {
            caseTreatmentModel.multiplier = 2;
            caseTreatmentModel.units = 2;
          }
        }
      } else if (x.description === "Aligners") {
        let reqA = isUsingGraphyWorkflow;
        //let reqA = x.requirements.find(
        //  (z) => z.description === "Are you using the GRAPHY workflow?"
        //);

        if (x.serviceProviderSelected === "Fabricate") {
          caseTreatmentModel.productId = 1017;
        } else if (x.serviceProviderSelected === "Design") {
          if (reqA != null) {
            if (reqA === true) {
              if (
                currentSubDomain === "graphy" ||
                currentSubDomain === "preprodgraphy"
              ) {
                caseTreatmentModel.productId = 1263;
              } else {
                caseTreatmentModel.productId = 1257;
              }
            } else if (reqA.value === false) {
              caseTreatmentModel.productId = 1018;
            } else {
            }
          } else {
            caseTreatmentModel.productId = 1018;
          }
        }
      } else if (x.description === "Models") {
        let reqA = x.requirements.find(
          (z) => z.description === "Alignment On Semi-Adjustable Articulator"
        );

        let reqM = x.requirements.find(
          (z) => z.description === "Type of Model"
        );
        if (typeof reqM !== "undefined") {
          if (reqM.value === "Base Model") {
            if (typeof reqA !== "undefined") {
              if (reqA.value === "Yes") {
                caseTreatmentModel.productId = 1014;
              } else {
                caseTreatmentModel.productId = 1040;
              }
            } else {
              caseTreatmentModel.productId = 1040;
            }
          } else if (reqM.value === "Implant Model") {
            caseTreatmentModel.productId = 1051;
          } else if (reqM.value === "Ortho Model") {
            caseTreatmentModel.productId = 1053;
          }
        }
      } else if (x.description === "Implant Crowns") {
        let castValue = x.requirements.find(
          (z) => z.description === "Type of Implant Restoration"
        );
        /* let tiValue = x.requirements.find(
              (z) => z.description === "Will this be on Ti-Base?"
            ); */

        if (castValue.value === "Screw-Retained Crown on Ti-Base") {
          caseTreatmentModel.productId = 1058;
        } else if (
          castValue.value === "Screw-Retained Crown (Direct to Fixture)"
        ) {
          caseTreatmentModel.productId = 1021;
        } else if (
          castValue.value === "Cement Retained Crown w/ Custom Abutment"
        ) {
          caseTreatmentModel.productId = 1060;
        } else if (
          castValue.value === "Screw-Retained Crown w/ Custom Abutment"
        ) {
          caseTreatmentModel.productId = 1267;
        } else if (castValue.value === "Custom Abutment (Abutment Only)") {
          caseTreatmentModel.productId = 1019;
        } else if (castValue.value === "Crown with Access Hole (Crown Only)") {
          caseTreatmentModel.productId = 1022;
        }
      } else if (x.description === "Clear Retainers") {
        let graphyValue = isUsingGraphyWorkflow;

        let reqtValue = x.requirements.find(
          (z) => z.description === "How many retainers do you need?"
        );
        let archCountValue = x.requirements.find(
          (z) => z.description === "This Clear Retainer is for?"
        );

        let stlValue = x.requirements.find(
          (z) => z.description === "Are you fabricating In-house?"
        );

        let removedBracketsValue = x.requirements.find(
          (z) =>
            z.description ===
            "Do you need us to digitally remove the brackets? (Additional $25)"
        );

        //if(removedBracketsValue.value === "Yes")
        //{
        //  modelToGetPrice.productIds.push(1254);
        //  treat.productIds.push(1254);
        //}
        if (x.serviceProviderSelected === "Fabricate") {
          if (reqtValue.value === "Set of 3") {
            caseTreatmentModel.productId = 1099;
            if (archCountValue.value === "Both") {
              caseTreatmentModel.multiplier = 2;
              caseTreatmentModel.units = 2;
            } else {
              caseTreatmentModel.multiplier = 1;
              caseTreatmentModel.units = 1;
            }
          } else {
            caseTreatmentModel.productId = 1221;
            if (archCountValue.value === "Both") {
              caseTreatmentModel.multiplier = 2;
              caseTreatmentModel.units = 2;
            } else {
              caseTreatmentModel.multiplier = 1;
              caseTreatmentModel.units = 1;
            }
          }
        } else {
          if (graphyValue === true) {
            if (reqtValue.value === "Set of 3") {
              caseTreatmentModel.productId = 1251;
            } else {
              caseTreatmentModel.productId = 1250;
            }
          } else {
            if (stlValue.value === "Yes") {
              if (reqtValue.value === "Set of 3") {
                caseTreatmentModel.productId = 1252;
              } else {
                caseTreatmentModel.productId = 1252;
              }
            } else {
              if (reqtValue.value === "Set of 3") {
                caseTreatmentModel.productId = 1252;
                if (archCountValue.value === "Both") {
                  caseTreatmentModel.multiplier = 1;
                  caseTreatmentModel.units = 1;
                }
              } else {
                caseTreatmentModel.productId = 1252;
                if (archCountValue.value === "Both") {
                  caseTreatmentModel.multiplier = 1;
                  caseTreatmentModel.units = 1;
                }
              }
            }
          }
        }
      } else if (x.description === "Devices") {
        let reqtValue = x.requirements.find(
          (z) => z.description === "Which Protocol do you want?"
        );
        let deprogValue = x.requirements.find(
          (z) => z.description === "Deprogrammer Options"
        );

        let nightguardValue = x.requirements.find(
          (z) => z.description === "Nightguard Options"
        );

        let mandebularValue = x.requirements.find(
          (z) => z.description === "Mandibular Anterior Jig Options"
        );

        let orthopedicValue = x.requirements.find(
          (z) => z.description === "Orthopedic Appliance Options"
        );

        if (reqtValue.value === "Kois Deprogrammer") {
          if (deprogValue.value === "Standard") {
            caseTreatmentModel.productId = 1029;
          } else if (deprogValue.value === "With Anterior Coverage") {
            caseTreatmentModel.productId = 1030;
          } else if (deprogValue.value === "With Posterior Coverage") {
            caseTreatmentModel.productId = 1031;
          }
        } else if (reqtValue.value === "Kois Nightguard") {
          if (nightguardValue.value === "Maxillary with anterior platform") {
            caseTreatmentModel.productId = 1035;
          } else if (
            nightguardValue.value === "Maxillary without anterior platform"
          ) {
            caseTreatmentModel.productId = 1034;
          } else if (nightguardValue.value === "Mandibular") {
            caseTreatmentModel.productId = 1036;
          }
        } else if (reqtValue.value === "Mandibular Anterior Jig") {
          if (mandebularValue.value === "Platform") {
            caseTreatmentModel.productId = 1038;
          } else if (mandebularValue.value === "Cobal Balancer") {
            caseTreatmentModel.productId = 1039;
          }
        } else if (reqtValue.value === "Orthopedic Appliance") {
          if (orthopedicValue.value === "Mandibular") {
            caseTreatmentModel.productId = 1033;
          } else if (orthopedicValue.value === "Maxillary") {
            caseTreatmentModel.productId = 1032;
          }
        }
      } else if (x.description === "Casts") {
        let reqtValue = x.requirements.find(
          (z) => z.description === "Which cast do you want?"
        );
        if (
          reqtValue.value === "Facially Referenced Mounted and Printable Casts"
        ) {
          caseTreatmentModel.productId = 1026;
        } else if (reqtValue.value === "Stone Mountable Printable Casts") {
          caseTreatmentModel.productId = 1040;
        } else if (reqtValue.value === "Hinge Mounted Casts") {
          caseTreatmentModel.productId = 1041;
        }
      } else if (x.description === "Custom Tray") {
        let archCountValue = x.requirements.find(
          (z) => z.description === "Arches"
        );
        caseTreatmentModel.productId = 1050;
        if (archCountValue.value === "Both") {
          caseTreatmentModel.units = 2;
          caseTreatmentModel.multiplier = 2;
        }
      } else if (x.description === "Digital Complete Dentures") {
        let archCountValue = x.requirements.find(
          (z) => z.description === "Arches"
        );
        if (archCountValue.value === "Both") {
          caseTreatmentModel.units = 2;
          caseTreatmentModel.multiplier = 2;
        }
        let dentureType = x.requirements.find(
          (z) => z.description === "Type of Complete Dentures"
        );
        if (dentureType === "Full Dentures") {
          caseTreatmentModel.productId = 1046;
        } else if (dentureType === "Truedent Denture") {
          caseTreatmentModel.productId = 1268;
        }
      } else if (x.description === "Digital Partial Dentures") {
        let reqtValue = x.requirements.find(
          (z) =>
            z.description ===
            "Cast Partial Framework or Acrylic Partial Denture"
        );
        let archCountValue = x.requirements.find(
          (z) => z.description === "Tooth Arch"
        );
        if (reqtValue.value === "Acrylic Partial Denture") {
          caseTreatmentModel.productId = 1056;
        } else if (reqtValue.value === "Cast Partial Framework") {
          caseTreatmentModel.productId = 1047;
          if (archCountValue.value === "Both") {
            caseTreatmentModel.units = 2;
            caseTreatmentModel.multiplier = 2;
          }
        }
      } else if (x.productId === 1249) {
        caseTreatmentModel.caseType = "Kois-Diagnostic Wax up";
      } else if (x.description === "CEREC Crown & Bridge") {
        caseTreatmentModel.productId = 1285;
      }

      //  else if (x.description === "Photographic Case Presentation") {
      //   let reqtValue = x.requirements.find(
      //     (z) => z.description === "Which Presentation do you want?"
      //   );
      //   reqtValue.value.forEach((val) => {
      //     if (val === "Dentofacial Analysis (DFA)") {
      //       caseTreatmentModel.productId = 1027;
      //     }
      //     if (val === "Virtual Smile Mock up with Outline") {
      //       caseTreatmentModel.productId = 1028;
      //     }
      //   });
      // }
      console.log(caseTreatmentModel);

      let treatmentsProductsList = [];
      treatmentsMaster.forEach((x) => {
        treatmentsProductsList = treatmentsProductsList.concat(
          x.treatmentTypes
        );
      });

      let reqDesc = treatmentsProductsList.find(
        (x) => x.productId === caseTreatmentModel.productId
      );
      console.log(reqDesc);
      if (reqDesc !== undefined && reqDesc !== null) {
        caseTreatmentModel.caseType = reqDesc.description;
        caseTreatmentModel.caseTypeParent = reqDesc.description;
      }

      model.CaseTreatments.push(caseTreatmentModel.caseType);
      model.CaseTreatmentModels.push(caseTreatmentModel);

      let requirements = [
        // {
        //   description: "Type of Restoration (Multiple)",
        //   value: JSON.stringify(toothNumberRestorations),
        // },
        // {
        //   description: "Additive wax-up",
        //   value: "No",
        // },
      ];
      if (toothNumberRestorations.length > 0) {
        requirements.push({
          description: "Type of Restoration (Multiple)",
          value: JSON.stringify(toothNumberRestorations),
        });
      }

      if (
        caseTreatmentModel.productId == 1285 &&
        toothNumberRestorations.length > 0
      ) {
        x.requirements.push({
          description: "Type of Restoration (Multiple)",
          value: JSON.stringify(toothNumberRestorations),
        });
      }
      let productDetails = [];

      if (
        x.requirements && typeof x.requirements.find((z) => z.description === "Tooth Chart") !==
        "undefined"
      ) {
        let toothChartReq = {
          reqName: "Tooth Chart",
          reqValue: toothNumbers.join(","),
        };
        console.log(toothChartReq, toothNumberRestorations.length);

        if (toothNumberRestorations.length > 0) {
          productDetails.push(toothChartReq);

          if (
            typeof x.requirements.find(
              (z) => z.description === "Type of Restoration (Multiple)"
            ) !== "undefined"
          ) {
            let toothChartRestReq = {
              reqName: "Type of Restoration (Multiple)",
              reqValue: "",
            };

            const groupedTeeth = groupTeeth(x.toothNumbers);
            const toothRestString = groupedTeeth.map((group) => {
              if (caseTreatmentModel.productId === 1285) {
                return (
                  `Tooth ${group.teeth.join(", ")} ` +
                  `(${group.restorationType}` +
                  (group.designMode
                    ? ", Design Mode: " + group.designMode
                    : "") +
                  (group.device ? ", Device: " + group.device : "") +
                  (group.material ? ", Material: " + group.material : "") +
                  (group.materialClass
                    ? ", Material Class: " + group.materialClass
                    : "") +
                  ")" +
                  (group.notes ? ` Notes: ${group.notes}` : "")
                );
              } else {
                return (
                  `Tooth ${group.teeth.join(", ")} ` +
                  `(${group.restorationType}` +
                  (group.widthAndLength > 0
                    ? ", " +
                      group.widthAndLength +
                      " mm Implant Width and Length"
                    : "") +
                  (group.gingivalContouring ? ", Gingivial Contouring" : "") +
                  ")" +
                  (group.notes ? ` Notes: ${group.notes}` : "")
                );
              }
            });

            toothChartRestReq.reqValue = toothRestString.join(", ");
            productDetails.push(toothChartRestReq);
          }
        }
      }

      console.log(x.requirements);
      if (x.description === "Surgical Planning and Guide") {
        console.log(x.requirements);
        let fullArch = x.requirements.find(
          (x) => x.description === "Is this a full arch design?"
        );
        if (fullArch.value === "Yes") {
          x.requirements = x.requirements.filter(
            (z) =>
              z.description !== "Tooth Chart" &&
              z.description !== "Type of Restoration (Multiple)"
          );
        } else if (fullArch.value === "No") {
          x.requirements = x.requirements.filter(
            (z) =>
              z.description !== "Arch Design" &&
              z.description !== "Does this include a restorative design?"
          );
        }
      }
      console.log(x.requirements);
      if(x.requirements){
          x.requirements
          .filter(
            (z) =>
              z.description !== "Tooth Chart" &&
              z.description !== "Type of Restoration (Multiple)"
          )
          .forEach((z) => {
            if (x.description === "Digital Complete Dentures") {
              console.log(z);
            }
            let valueOfReq = z.value;
            let arrayJoined = null;

            if (
              typeof z.measurement !== "undefined" &&
              z.measurement !== -1 &&
              z.measurement !== ""
            ) {
              valueOfReq = z.value + " : " + z.measurement + "mm";
            }

            if (typeof z.printer !== "undefined" && z.printer !== "") {
              valueOfReq = valueOfReq + " - " + z.printer;
            }
            if (typeof z.printerModel !== "undefined" && z.printerModel !== "") {
              valueOfReq = valueOfReq + " - " + z.printerModel;
            }

            if (typeof z.caseNumber !== "undefined" && z.caseNumber !== "") {
              valueOfReq = valueOfReq + " - " + z.caseNumber;
            }

            if (typeof z.material !== "undefined" && z.material !== "") {
              valueOfReq = valueOfReq + " - " + z.material;
            }

            if (typeof z.notes !== "undefined" && z.notes !== "") {
              valueOfReq = valueOfReq + " - " + z.notes;
            }

            if (Array.isArray(z.value)) {
              valueOfReq = JSON.stringify(z.value);
              // use array.join() for pdf generation value
              arrayJoined = z.value.join(", ");
            }

            if (typeof z.type !== "undefined" && z.type !== "") {
              valueOfReq = valueOfReq + " - " + z.type;
            }

            if (typeof z.pins !== "undefined" && z.pins !== "") {
              valueOfReq = valueOfReq + " - " + z.pins;
            }

            if (
              typeof z.ImplantScanbody !== "undefined" &&
              z.ImplantScanbody !== ""
            ) {
              valueOfReq = valueOfReq + " - " + z.ImplantScanbody;
            }

            if (
              typeof z.upper !== "undefined" &&
              z.upper !== -1 &&
              z.upper !== ""
            ) {
              valueOfReq = valueOfReq + ` Upper ${z.upper}mm `; // "Upper" + " - " + z.upper + "mm";
            }

            if (
              typeof z.lower !== "undefined" &&
              z.lower !== -1 &&
              z.lower !== ""
            ) {
              valueOfReq = valueOfReq + ` Lower ${z.lower}mm `; // "Upper" + " - " + z.upper + "mm";
            }

            if (typeof z.tick !== "undefined" && z.tick !== -1) {
              valueOfReq =
                valueOfReq +
                ` Overjet and Overbite would be idealized per situation: ${
                  z.tick ? "Yes" : "No"
                }`;
            }

            if (
              typeof z.preferredLibrary !== "undefined" &&
              z.preferredLibrary !== ""
            ) {
              valueOfReq = valueOfReq + " - " + z.preferredLibrary;
            }

            if (typeof z.nest !== "undefined" && z.nest !== -1) {
              valueOfReq =
                valueOfReq +
                `- Should we nest the design for you (order 3D printable file)? ${
                  z.nest ? "Yes" : "No"
                }`;
            }

            if (typeof z.marking !== "undefined" && z.marking !== "") {
              valueOfReq = valueOfReq + " - " + z.marking;
            }

            if (typeof z.dies !== "undefined" && z.dies !== "") {
              valueOfReq = valueOfReq + " - " + z.dies;
            }
            if (typeof z.thickness !== "undefined" && z.thickness !== "") {
              let thicknessValue = "";
              if (z.thickness == true) {
                thicknessValue = "Yes";
              } else {
                thicknessValue = "No";
              }
              valueOfReq =
                valueOfReq +
                " - " +
                "Raise the thickness of the aligner in the posteriors? " +
                thicknessValue;
            }
            if (
              z.description === "Radial Spacer" ||
              z.description === "Occlusal Spacer" ||
              z.description === "Proximal Contact Strength" ||
              z.description === "Occlusal Contact Strength" ||
              z.description === "Dynamic Contact Strength" ||
              z.description === "Minimal Radial Thickness" ||
              z.description === "Minimal Occlusal Thickness" ||
              z.description === "Margin Thickness" ||
              z.description === "Width of Ramp" ||
              z.description === "Angle of Ramp" ||
              z.description === "Type of Restoration (Multiple)"
            ) {
              valueOfReq = `${z.value}µm `;
            }

            console.log(
              componentsVisible.find((r) => r.description === z.description)
            );

            if (
              componentsVisible.find((r) => r.description === z.description) !==
                undefined &&
              componentsVisible.find((r) => r.description === z.description)
                .visible === false
            ) {
              console.log(
                z.description,
                "dont add the requirement it is not visible"
              );
            } else {
              // if value is falsey, change it to empty string
              if (!valueOfReq) {
                valueOfReq = "";
              }

              let requirement = {
                reqName: z.description,
                reqValue: valueOfReq,
              };

              let reqForOrderSummary = {
                description: z.description,
                value: arrayJoined || valueOfReq,
              };

              productDetails.push(requirement);
              requirements.push(reqForOrderSummary);
            }
          });
      }

      if (
        x.description !==
        "Surgical Planning and Guide - individual teeth (Additional tooth)"
      ) {
        commentModel.products.push({
          productName: x.description,
          productDetails: productDetails,
          productNotes: x.additionalNotes,
        });
      }

      console.log(x);
      console.log(selectedTreatment[0].treatmentCategoryDescription);
      let orderSummaryProduct = {
        description: x.description,
        hasToothChart:
          Boolean(
            x.requirements && x.requirements.find((r) => r.description === "Tooth Chart")
          ) && Boolean(x.toothChartImage),
        logoUrl: null,
        productCategory: x.treatmentCategoryDescription,
        ...(toothNumbers.length > 0 && {
          toothChartList: JSON.stringify(toothNumbers),
        }),
        toothChartUrl: x.toothChartImage || "",
        withImpression: false,
        requirements: requirements,
        notes: x.additionalNotes,
      };

      if (
        x.description !==
        "Surgical Planning and Guide - individual teeth (Additional tooth)"
      ) {
        model.OrderSummary.orderProducts.push(orderSummaryProduct);
      }
    });

    model.Comment = JSON.stringify(commentModel);

    const userPhone = await getPhoneByEmail(userInfo.Email);

    CaseService.submitCase(model)
      .then((response) => response.json())
      .then((json) => {
        setCaseConfirmationResult(json);
        setIsLoadingModalOpen(false);
        setCaseCreatedModalOpen(true);

        // For CRO, add params in url
        const treatmentNames = treatmentsSelected.map(
          (treatment) => treatment.description
        );
        const treatmentPackages = treatmentNames.map(
          (treatmentName) => `treatment_package=${treatmentName}`
        );

        //conversions api should be here
        const user = {
          em: [sha256(userInfo.Email)],
          ph: [sha256(userPhone)],
          fn: [sha256(userInfo.FirstName)],
          ln: [sha256(userInfo.LastName)],
        };
        // conversionsTriggerPurchaseEvent(user,totalAmount,treatmentsSelected);

        if (!Boolean(patientDetails.IsPrebook)) {
          navigate(
            `/?order_id=${json.refId}&user_name=${
              userInfo.Email
            }&${treatmentPackages.join("&")}`
          );
        } else {
          setSearchParams({
            order_id: json.refId,
            user_name: userInfo.Email,
            treatmentPackages: treatmentNames,
          });
        }
      });
  };

  const segmentTriggerEvent = (event) => {
    segment.trigger({
      event,
      properties: {
        products: formattedTreatments.map((treatment) => {
          return {
            product_name: treatment.description,
            product_category: treatment.treatmentCategoryDescription,
          };
        }),
      },
    });
  };

  const confirmSubmission = (values) => {
    segmentTriggerEvent("hub_confirm_order");
    setAccountName(values.accountName);
    setCardNumber(values.cardNumber);
    setExpiryMonth(values.expiryMonth);
    setExpiryYear(values.expiryYear);
    setCcv(values.cvv);
    if (hasPaymentDetails) {
      handleOpenBillingOptionModal();
      //setConfirmationModalOpen(true);
    } else {
      if (luhn_checksum(values.cardNumber) === 0) {
        setSubmittingCard(true);
        console.log("dentistByPractice", dentistByPractice);
        let paymentDetailsModel = {
          id: pId,
          name: dentistByPractice.FirstName + " " + dentistByPractice.LastName,
          email: dentistByPractice.Email,
          cardHolderName: values.accountName,
          creditCardNumber: values.cardNumber,
          expirationMonth: Number(values.expiryMonth),
          expirationYear: Number(values.expiryYear),
          cvvNumber: values.cvv,
        };

        AdminService.addPaymentDetails(paymentDetailsModel, design_domain)
          .then((response) => response.json())
          .then((jsonDesign) => {
            setSubmittingCard(false);
            if (jsonDesign.error === null) {
              handleOpenBillingOptionModal();
              //setConfirmationModalOpen(true);
            } else {
              console.log("jsonDesign", jsonDesign);
              var errorMsg = JSON.parse(jsonDesign.error.message);
              console.log("errorMsg", errorMsg);
              if (errorMsg.Error.DisplayMessage === null) {
                setPaymentError(errorMsg);
              } else {
                setPaymentError(errorMsg.Error.DisplayMessage);
              }
            }
          });
      } else {
        setPaymentError("Invalid credit card number");
      }
    }
  };

  const luhn_checksum = (code) => {
    var len = code.length;
    var parity = len % 2;
    var sum = 0;
    for (var i = len - 1; i >= 0; i--) {
      var d = parseInt(code.charAt(i));
      if (i % 2 === parity) {
        d *= 2;
      }
      if (d > 9) {
        d -= 9;
      }
      sum += d;
    }
    return sum % 10;
  };

  const handleNoAccessModalClose = (confirm) => {
    setOpenNoAccessModal(false);

    console.log("promptPrepay ", checkStatusResult);
    if (checkStatusResult == "promptPrepay") {
      setConfirmationModalOpen(true); // allow to process
    }
  };
  const handleCaseCreatedModalClose = (confirm) => {
    setCaseCreatedModalOpen(false);

    let confirmationDetails = {
      RefNo: caseConfirmationResult.refId,
      Id: caseConfirmationResult.response,
    };

    handleNextStep(1, treatments, patientDetails, confirmationDetails);
  };

  useEffect(() => {
    if (!formattedTreatments) {
      return;
    }

    const getProductUpsells = async (productIds) => {
      const response = await fetch(
        apiUrl + "product/recommendations?productIds=" + productIds
      );
      const data = await response.json();

      return data;
    };
    const getUpsellProductsBasedOnTreatments = async () => {
      const selectedTreatmentIds = selectedTreatment.map((product) => {
        if (
          product.id !== process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID ||
          (product.id === process.env.REACT_APP_ALL_ON_X_IMPLANTS_ID &&
            !product.isPackage)
        ) {
          return product.id;
        }
      });

      const selectedTreatmentIdsString = selectedTreatmentIds.join(",");
      const upsellProducts = await getProductUpsells(
        selectedTreatmentIdsString
      );
      // clean up, remove selected treatment in upsell product ids
      const cleanUpsellProducts = upsellProducts.filter(p => !selectedTreatmentIds.includes(p));
      const upsellProductsToShow = [];

      cleanUpsellProducts.map((id) => {
        if (id === "fileNesting") {
          if (!selectedTreatmentIds.includes(fileNestingProduct.id) && !formattedTreatments.map(t => t.id).includes(fileNestingProduct.id)) {
            upsellProductsToShow.push(fileNestingProduct);
          }
        }

        const productFound = productsToShow.filter(
          (product) => product.id === id
        );

        if (productFound.length) {
          upsellProductsToShow.push(productFound[0]);
        }
      });

      setIsLoadingUpsellProducts(false);
      setUpsellProducts(upsellProductsToShow);
    };

    getUpsellProductsBasedOnTreatments();
  }, [formattedTreatments]);
  

  console.log(isSubmittingCard);
  return (
    <div style={{ paddingBottom: "100px" }}>
      <BillingOptionModal
        isOpen={openBillingModal}
        initUserInfo={userInfo}
        handleClose={handleBillingOptionClose}
        onSelectBillingOption={handleSelectBillingOption}
      />
      <NoAccessPrepayModal
        isOpen={openNoAccessModal}
        msg={errorMessage}
        handleClose={handleNoAccessModalClose}
        isDentist={isDentistRole}
      />

      <CreateCaseConfirmationModal
        isOpen={confirmationModalOpen}
        handleClose={handleConfirmClose}
      />
      <CaseCreatedModal
        isOpen={caseCreatedModalOpen}
        handleClose={handleCaseCreatedModalClose}
      />
      <LoadingModal
        open={isSubmittingCard}
        message="Please wait while we save your card details"
      />
      <LoadingModal open={isLoadingModalOpen} message="Case being created" />
      <Box sx={{ textAlign: "center", marginTop: "45px" }}>
        <Box
          sx={{
            color: "#0A0909",
            fontSize: "48px",
            fontWeight: "300",
            lineHeight: "70px",
          }}
        >
          Order Details
        </Box>
      </Box>

      <Divider
        sx={{
          border: "0.4px dashed #6C6C6C",
          marginTop: "30px",
          marginBottom: "60px",
        }}
      />

      {!isLoading ? (
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            height: "100%",
            borderLeft: "1px dashed " + brandGray,
          }}
        >
          {/* Treatments Added */}

          <Box sx={{ display: "flex", alignItems: "start", height: "57px" }}>
            <Box>
              <Divider
                sx={{
                  border: "1px dashed #969696",
                  width: "64px",
                  height: "0px",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "57px",
                height: "57px",
                borderRadius: "50%",
                background: "#414141",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: "32px",
                lineHeight: "31px",
                position: "relative",
                top: "-28px",
              }}
            >
              1
            </Box>
            <Box
              sx={{ position: "relative", top: "-28px", marginLeft: "20px" }}
            >
              <Box
                sx={{ fontSize: "28px", lineHeight: "31px", fontWeight: "400" }}
              >
                Order Review
              </Box>
              <Box
                sx={{
                  marginTop: "7px",
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: brandGray,
                }}
              >
                Review all of the treatments you've added before proceeding with
                payment
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              background: brandLight,
              backdropFilter: " blur(20px)",
              borderRadius: "16px",
              marginLeft: "64px",
              width: "90%",
              paddingTop: "45px",
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "45px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    color: "#37465D",
                  }}
                >
                  Treatment/s
                </Box>
              </Grid>
              <Grid item sm={6} sx={{ textAlign: "right" }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    color: "#37465D",
                  }}
                >
                  Evident Price
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Divider
                  sx={{
                    border: "0.4px dashed #6C6C6C",
                    marginTop: "18px",
                    marginBottom: "18px",
                  }}
                />
              </Grid>

              {formattedTreatments.map((treatment, index) => (
                <>
                  <Grid item sm={9}>
                    <Box
                      sx={{ width: "100%", display: "flex", marginBottom: 2 }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 10,
                          width: "100%",
                          height: "100%",
                          maxWidth: "150px",
                          maxHeight: "150px",
                          background: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <img
                          src={
                            treatment.imageLink
                              ? treatment.imageLink
                              : DiagnosticWaxUp
                          }
                          style={{
                            objectFit: "cover",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            borderRadius: "12px",
                          }}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "30px" }}>
                        <Box
                          sx={{
                            fontSize: "21px",
                            fontWeight: "500",
                            lineHeight: "25px",
                          }}
                        >
                          {treatment.description}
                        </Box>
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            lineHeight: "24px",
                            marginTop: "10px",
                          }}
                        >
                          {treatment.productDescription}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={3} sx={{ textAlign: "right" }}>
                    <Stack>
                      <Box
                        sx={{
                          fontSize: "26px",
                          fontWeight: "400",
                          lineHeight: "30px",
                        }}
                      >
                        $ {treatment.price.toFixed(2)} USD
                      </Box>
                      <Box
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "19px",
                          marginTop: "10px",
                          color: "#37465D",
                        }}
                      >
                        {treatment?.productId === 1001 ||
                        treatment?.productId === 1214 ||
                        treatment?.productId === 1044 ||
                        treatment?.productId === 1219 ||
                        treatment?.productId === 1046 ||
                        treatment?.productId === 1047 ||
                        treatment?.productId === 1050 ||
                        treatment?.productId === 1099 ||
                        treatment?.productId === 1221 ||
                        treatment?.productId === 1268
                          ? "*Per Arch"
                          : ""}

                        {/* treatment?.productId === 1040 || */}
                      </Box>
                    </Stack>
                  </Grid>
                </>
              ))}

              <Grid item sm={12}>
                <Divider
                  sx={{
                    border: "0.4px dashed #6C6C6C",
                    marginTop: "18px",
                    marginBottom: "18px",
                  }}
                />
              </Grid>

              <Grid item sm={12} sx={{ textAlign: "right" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    {isRush && (
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: 30,
                          color: brandRed,
                          fontWeight: 600,
                        }}
                      >
                        Rush Order
                        <br />
                        <Box component="span" fontWeight={400} fontSize={24}>
                          (Additional fees applies based on design type)
                        </Box>
                      </Typography>
                    )}
                    {isPrebook && (
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: 30,
                          color: brandOrange,
                          fontWeight: 600,
                        }}
                      >
                        Prebook Case - $99
                        <br />
                      </Typography>
                    )}
                  </Box>
                  <Stack spacing={1}>
                    <div>
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#3D3D3D",
                          lineHeight: "21px",
                        }}
                      >
                        Total Amount:{" "}
                      </span>
                      <span
                        style={{
                          fontSize: "32px",
                          fontWeight: "500",
                          lineHeight: "47px",
                        }}
                      >
                        $ {totalAmount.toFixed(2)} USD
                      </span>
                    </div>
                    {isRush && (
                      <Box
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: brandRed,
                          lineHeight: "16px",
                        }}
                      >
                        +Additional fees for rush order
                      </Box>
                    )}
                    {selectedTreatment.find(
                      (x) => x.description === "Aligners"
                    ) !== undefined &&
                      selectedTreatment.find(
                        (x) => x.description === "Aligners"
                      ).serviceProviderSelected === "Fabricate" && (
                        <Stack spacing={1}>
                          <div
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            <Button
                              sx={[
                                {
                                  textTransform: "none",
                                  background: brandBlue,
                                  borderRadius: "5px",
                                  width: "250px",
                                  height: "60px",
                                  color: "#F8F8F8",
                                  marginRight: "15px",
                                },
                                { "&:hover": { background: brandBlue } },
                              ]}
                              onClick={() => {
                                setIsOpenPricelistDialog(true);
                              }}
                            >
                              Pricelist
                            </Button>
                          </div>
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#3D3D3D",
                              lineHeight: "21px",
                            }}
                          >
                            *Please see Aligner pricing
                          </span>
                        </Stack>
                      )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              marginLeft: "64px",
              width: "90%",
              height: "184px",
              background: brandBlack,
              paddingLeft: "50px",
              paddingRight: "50px",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "60px",
              fontSize: "24px",
            }}
          >
            <Box
              sx={{
                color: "#fff",
                fontWeight: "500",
                color: "#fff",
                fontSize: "1.25em",
              }}
            >
              Note:
            </Box>
            <Box sx={{ color: "#fff", color: "#fff" }}>
              Prices shown <b>do not include</b> tax or any deductions.
            </Box>
            <Box sx={{ color: "#fff", color: "#fff" }}>
              Modifications to your prescriptions may result in{" "}
              <b>additional charges.</b>
            </Box>
            <Box sx={{ color: "#fff", color: "#fff" }}>
              The billing is an estimate and may change based on the treatment
              needed.
            </Box>
          </Box>

          {/* Payment Details */}

          {hasPaymentDetails === false ? (
            <>
              <Box
                sx={{ display: "flex", alignItems: "start", height: "57px" }}
              >
                <Box>
                  <Divider
                    sx={{
                      border: "1px dashed #969696",
                      width: "64px",
                      height: "0px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "57px",
                    height: "57px",
                    borderRadius: "50%",
                    background: "#414141",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: "32px",
                    lineHeight: "31px",
                    position: "relative",
                    top: "-28px",
                  }}
                >
                  2
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    top: "-28px",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "28px",
                      lineHeight: "31px",
                      fontWeight: "400",
                    }}
                  >
                    Payment Method
                  </Box>
                  <Box
                    sx={{
                      marginTop: "7px",
                      fontSize: "16px",
                      lineHeight: "16px",
                      color: brandGray,
                    }}
                  >
                    Please type in your payment information.
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  backdropFilter: " blur(20px)",
                  borderRadius: "16px",
                  marginLeft: "64px",
                  width: "90%",
                  paddingTop: "45px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingBottom: "45px",
                }}
              >
                {paymentError !== "" ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    sx={{
                      color: "red",
                      paddingBottom: "45px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    <b>{paymentError}</b>
                  </Box>
                ) : (
                  ""
                )}
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#37465D",
                      }}
                    >
                      Card Number
                    </Box>
                    <Controller
                      name="cardNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Card number is required.",
                        },
                        minLength: {
                          value: 15,
                          message: "Card number must at least be 15 numbers.",
                        },
                        maxLength: {
                          value: 16,
                          message: "Card number cannot exceed be 16 numbers.",
                        },
                      }}
                      defaultValue={cardNumber}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          type="number"
                          placeholder="Card Number"
                          sx={{ width: "100%", marginTop: "5px" }}
                        />
                      )}
                    />
                    <Box sx={{ color: "red" }}>
                      {errors.cardNumber?.message && errors.cardNumber?.message}
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#37465D",
                      }}
                    >
                      Account Name
                    </Box>
                    <Controller
                      name="accountName"
                      defaultValue={accountName}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="accountName"
                          variant="outlined"
                          placeholder="Account Name"
                          sx={{ width: "100%", marginTop: "5px" }}
                        />
                      )}
                    />
                    <Box sx={{ color: "red" }}>
                      {errors.accountName && "Account Name is required"}
                    </Box>
                  </Grid>
                  <Grid item sm={4}>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#37465D",
                        marginTop: "20px",
                      }}
                    >
                      Expiry Date
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item sm={6}>
                        <Controller
                          name="expiryMonth"
                          defaultValue={expiryMonth}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Card number is required.",
                            },
                            min: { value: 1, message: "Invalid month." },
                            max: { value: 12, message: "Invalid month." },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              variant="outlined"
                              placeholder="Expiry Month"
                              sx={{ width: "100%", marginTop: "5px" }}
                            />
                          )}
                        />
                        <Box sx={{ color: "red" }}>
                          {errors.expiryMonth?.message &&
                            errors.expiryMonth?.message}
                        </Box>
                      </Grid>
                      <Grid item sm={6}>
                        <Controller
                          name="expiryYear"
                          defaultValue={expiryYear}
                          control={control}
                          rules={{
                            required: true,
                            min: {
                              value: new Date().getFullYear,
                              message: "Invalid year",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              variant="outlined"
                              placeholder="Expiry Year"
                              sx={{ width: "100%", marginTop: "5px" }}
                            />
                          )}
                        />
                        <Box sx={{ color: "red" }}>
                          {errors.expiryYear && "Year is required"}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4}>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#37465D",
                        marginTop: "20px",
                      }}
                    >
                      CVV
                    </Box>
                    <Controller
                      name="ccv"
                      defaultValue={ccv}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "CVV number is required.",
                        },
                        minLength: {
                          value: 3,
                          message: "CVV number must be at least be 3 numbers.",
                        },
                        maxLength: {
                          value: 4,
                          message: "CVV cannot be more than 4 numbers.",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="password"
                          variant="outlined"
                          placeholder="CVV"
                          sx={{ width: "100%", marginTop: "5px" }}
                        />
                      )}
                    />
                    <Box sx={{ color: "red" }}>
                      {errors.ccv?.message && errors.ccv?.message}
                    </Box>
                  </Grid>
                  <Grid item sm={4}>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#37465D",
                        marginTop: "20px",
                      }}
                    >
                      Contact Details
                    </Box>
                    <TextField
                      id="contactDetails"
                      variant="outlined"
                      placeholder="Contact Details"
                      sx={{ width: "100%", marginTop: "5px" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ""
          )}

          <Box>
            {isLoadingUpsell && (
              <Stack
                marginTop={10}
                marginBottom={5}
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress color="success" />
                <Typography sx={{ marginTop: 1.5 }}>
                  Fetching products you may need
                </Typography>
              </Stack>
            )}

            {!isLoadingUpsell && upsellProducts.length > 0 && (
              <Box marginLeft="60px">
                <Grid2 item xs={12} lg={8} lgOffset={2}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    style={{
                      position: "absolute",
                      marginTop: "15px",
                      marginLeft: "20px",
                    }}
                  >
                    You also may need
                  </Typography>
                </Grid2>
                <Grid2 item xs={12} lg={8} lgOffset={2}>
                  <AdditionalProducts 
                    products={upsellProducts} 
                    selectedTreatment={selectedTreatment} 
                    patient={patientDetails}
                    onAddAdditional={onAddAdditional}
                    onClickAboutTreatment={onClickAboutTreatment}
                  />
                </Grid2>
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "end", width: "100%" }}>
            <Box sx={{ flex: 3 }}>
              <Divider
                sx={{
                  border: "1px dashed #969696",
                  width: "100%",
                  height: "0px",
                }}
              />
            </Box>
            <Box sx={{ width: "100%", flex: 1 }}>
              <Grid
                container
                spacing={1}
                sx={{ position: "relative", top: "28px" }}
              >
                <Grid item sm={12} sx={{ textAlign: "right" }}>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      sx={[
                        {
                          textTransform: "none",
                          background: brandGray,
                          borderRadius: "5px",
                          width: "250px",
                          height: "60px",
                          color: "#fff",
                          marginRight: "15px",
                        },
                        { "&:hover": { background: brandGray } },
                      ]}
                      onClick={cancelSubmission}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit(confirmSubmission)}
                      sx={{
                        textTransform: "none",
                        background: brandColor,
                        borderRadius: "5px",
                        width: "250px",
                        height: "60px",
                        color: "#fff",
                        "&:hover": { background: brandColor },
                      }}
                    >
                      Proceed
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="success" />
        </Box>
      )}

      <IframeDialog
        isOpen={isOpenPricelistDialog}
        handleClose={setIsOpenPricelistDialog}
        link={
          "https://evidentdigital.blob.core.windows.net/public/DENTIST_PRICELIST.pdf"
        }
      ></IframeDialog>
    </div>
  );
}

export default connect(mapStateToProps, null)(PaymentDetails);
